<template>
<div>
  <div class="container">
    <div class="historyList mb30">
      <div class="logoTitle colorBlack">
        <p class="main"><strong>경기장안내</strong></p>
      </div>
      <img src="@/assets/img/stadium.png" alt=""/>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Stadium',
  data () {
    return {}
  },
  methods: {}
}
</script>
