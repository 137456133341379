<template>
  <div class="containerWithArrow">
    <div>
      <div class="backSpace" @click="goBack"/>
      <div class="logoTitle colorBlack">
        <p class="main"><strong>마이 홈 데뷔 그라운드 체험 신청</strong></p>
      </div>
    </div>
    <div class="mb30">
      <div class="surveyTileBox">
        <div class="mt30 eventBox">
          <img class="img" src="@/assets/img/fix_event_image_2024.png" alt="">
        </div>
      </div>
    </div>
    <eagle-box
      v-show="!onEvent"
      :title="btnTmp.title"
      :sub-title="btnTmp.subTitle"
      :is-btn="btnTmp.isBtn"
      :btn-type="btnTmp.btnType"
      :btn-name="btnTmp.btnName"
    />
    <div class="mb30">
      <p class="lineTitle">이벤트 참여</p>
      <div class="tileBox">
        <div class="formGroup">
          <div class="label">이름</div>
          <div class="value">
            <input type="text" class="input" disabled v-model="form.name">
          </div>
        </div>
        <div class="formGroup">
          <div class="label">연락처</div>
          <div class="value">
            <input type="tel" class="input" disabled v-model="form.mobile">
          </div>
        </div>
        <div class="formGroup">
          <div class="label">경기선택</div>
          <div class="value">
            <select class="input select" v-model="form.gameDate" @change="changeArrow">
              <option value="">경기를 선택해주세요</option>
              <option v-for="(el, i) in playList" :key="i" :value="el.date">{{el.date}} {{ el.home }} VS {{el.away}}</option>
            </select>
          </div>
        </div>

        <div class="formGroup">
          <div class="label">단체명(이름)</div>
          <div class="value">
            <input type="text" class="input" placeholder="단체명(이름)을 입력해주세요" v-model="form.groupName">
          </div>
        </div>

        <div class="formGroup">
          <div class="label">신청인원</div>
          <div class="value">
            <select class="input select" v-model="form.joinCnt">
              <option value="">신청 인원을 선택해주세요</option>
              <option v-for="(num, i) in peopleCount" :key="i" :value="num">{{ num }}</option>
            </select>
          </div>
        </div>

        <div class="formGroup">
          <div class="label">신청사연</div>
          <div class="value">
            <textarea class="input" placeholder="신청사연을 입력해주세요" v-model="form.reason" @input="checkLength"/>
          </div>
        </div>

        <div class="formGroup">
          <div class="label">첨부파일</div>
          <div class="value">
            <div class="fileBox">
              <label v-if="file1" class="fileInput" for="upload_file1">{{file1}}</label>
              <label v-else class="uploadBox" for="upload_file1">첨부파일을 업로드하세요</label>
              <input type="file" id="upload_file1" name="file1" ref="file1" @change="fileUpload" accept=".jpeg,.jpg,.png,.gif">
              <div class="fileBtnBox mouseCursor" v-show="file1" ><img src="@/assets/img/ico_cancel.png" @click="deleteFile('file1')" alt=""></div>
            </div>
            <div class="uploadBox" v-show="!file1">
              <label for="upload_file1" class="btn compact mt10 mouseCursor">첨부파일 업로드</label>
            </div>
          </div>
        </div>
        <!-- agree -->
        <div class="mt20">
          <p class="mb10" style="padding-left: 15px; font-weight: 500">
            개인정보 수집 및 이용
            <span class="colorRed">(필수)</span>
          </p>
          <div class="textInput mb10">
            <div class="agreeBox fz14 light colorGrey" v-html="privacyAgreeText"></div>
          </div>
          <div class="agreeCheck">
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_Y" value="Y" checked v-model="agree">
              <label for="agree_privacy_Y">동의</label>
            </span>
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_N" value="N" v-model="agree">
              <label for="agree_privacy_N">미동의</label>
            </span>
          </div>
        </div>
        <!-- //agree -->
      </div>
    </div>
      <button type="button" class="btn full mt30" @click="submit">참여하기</button>
      <button type="button" class="btn lightGrey full mt20" @click="goBack">이벤트 리스트</button>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import regExp from '@/common/regExp'
import terms from '@/library/terms'
import EagleBox from '@/common/EagleBox.vue'

export default {
  name: 'FixedEventView',
  components: {EagleBox},
  data () {
    return {
      onEvent: true,
      playList: [],
      privacyAgreeText: terms.fixEvent_terms,
      peopleCount: [11, 22],
      form: {
        name: '',
        mobile: '',
        gameDate: '',
        groupName: '',
        joinCnt: '',
        reason: '',
        id: ''
      },
      btnTmp: {
        title: '해당 이벤트는 아직 신청전 입니다.',
        subTitle: '이벤트 시간에 맞춰 참여해주세요.',
        isBtn: true,
        btnName: '페이지 새로고침',
        btnType: 'refrash'
      },
      agree: '',
      file1: ''
    }
  },
  created () {
    this.eventIdx = this.$route.params.id
    this.fetchData()
    this.init()
  },
  methods: {
    init () {
      api.get('/user/detail').then(res => {
        const user = res.data.data.user
        if (user) {
          this.form.name = user.name
          this.form.mobile = user.mobileno
          this.form.id = user.user_id
        }
      })
    },
    fetchData () {
      api.get('/game').then(res => {
        if (res.data.data && res.data.data.future_game) {
          const playList = res.data.data.future_game.filter(el => el.home_team_name === '경남')
          if (playList.length > 0) {
            this.playList = playList.map(gameData => ({
              home: gameData.home_team_name,
              away: gameData.away_team_name,
              date: dayjs(gameData.game_date).format('YYYY-MM-DD')
            }))
          }
        }
      })
    },
    validate () {
      let result = true
      if (regExp.replaceAllEmpty(this.form.gameDate) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (regExp.replaceAllEmpty(this.form.groupName) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (this.form.joinCnt === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (regExp.replaceAllEmpty(this.form.reason) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      } else if (regExp.replaceAllEmpty(this.file1) === '') {
        alert('누락된 항목이 있습니다.')
        result = false
      }
      return result
    },
    submit () {
      if (this.validate()) {
        if (this.agree === '') {
          alert('개인정보 수집 및 이용 동의는 필수 선택 항목입니다.')
          return
        } if (this.agree === 'N') {
          alert('개인정보 수집 및 이용을 미동의할 경우 이벤트 참여에 제한됩니다.')
          return
        }

        const formData = new FormData()

        formData.append('game_date', this.form.gameDate)
        formData.append('join_id', this.form.id)
        formData.append('join_name', this.form.name)
        formData.append('group_name', this.form.groupName)
        formData.append('phone', this.form.mobile)
        formData.append('group_img', this.$refs.file1.files[0])
        formData.append('reason', this.form.reason)
        formData.append('join_cnt', this.form.joinCnt)

        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value, typeof value)
        // }

        api.post('/event/escortkids/apply', formData).then(res => {
          if (res.data.err) {
            alert(res.data.msg)
            return
          }
          alert('이벤트 참여가 완료되었습니다.\n참여해 주셔서 감사합니다.')
          this.$router.push({ name: 'eventHistory' })
        })
      }
    },
    fileUpload (e) {
      const file = e.target.files[0]
      const filename = file.name
      const nameLen = filename.lastIndexOf('.')
      const fullLen = filename.length
      const fileType = filename.substring(nameLen + 1, fullLen).toLowerCase()
      const maxSize = 10 * 1024 * 1024 // 10MB
      if (e.target.files[0].size > maxSize) {
        return alert('첨부파일은 10MB 이하의 용량만 첨부 가능합니다.')
      }
      const arr = ['jpeg', 'jpg', 'png', 'gif']
      if (!arr.includes(fileType)) {
        alert('첨부파일은 JPG, JPEG, PNG, GIF 파일로만 가능합니다.')
        this.fileReset()
        return
      }
      this.file1 = file.name
    },
    deleteFile (name) {
      if (confirm('첨부파일을 삭제하시겠습니까?')) {
        this.$refs[name].value = ''
        this[name] = ''
      }
    },
    fileReset () {
      this.$refs.file1 = null
      this.file1 = ''
    },
    goBack () {
      this.$router.go(-1) // 이전 페이지로 이동
    },
    checkLength () {
      if (this.form.reason.length > 500) {
        alert('최대 500자까지 입력할 수 있습니다.')
        this.form.reason = this.form.reason.substring(0, 500)
      }
    },
    changeArrow () {
      document.activeElement.blur()
    }
  }
}
</script>
<style scoped lang="scss">
textarea {
  width: 100%;
  height: 6.25em;
  resize: none;
}
</style>
