<template>
<div></div>
</template>

<script>
import http from '@/api'
import regExp from '@/common/regExp'
import dayjs from 'dayjs'

export default {
  name: 'AppLogin',
  data () {
    return {
      token: '',
      id: '',
      pw: '',
      redirectUrl: '/'
    }
  },
  created () {
    if (this.$route.query.redirect) {
      this.redirectUrl = this.$route.query.redirect
    }

    // userAgent check for mobile gmembers string include
    if (navigator.userAgent.includes('gmembers')) {
      this.init()
    } else {
      // alert wrong access
      alert('잘못된 접근입니다.')
      this.$router.push('/')
    }
  },
  methods: {
    init: function () {
      if (this.$route.query.status === 'migration') {
        // alert('더 안전한 서비스를 위해 회원님의 정보 최신화가 필요합니다.\n원활한 홈페이지를 이용하기 위해서 내 정보 최신화에 참여해주세요.')
        // return window.open('https://www.gyeongnamfc.com/member/certification.php', '_blank')
      }

      // get accessToken from query String
      if (this.$route.query.accessToken) {
        this.token = this.$route.query.accessToken
        this.$store.commit('login', true)
        localStorage.setItem('token', this.token)
        if (this.$route.query.change_pw === 'true') {
          alert('마지막 비밀번호를 변경한지 180일이 지났습니다.\n비밀번호를 변경해주세요.')
          return this.$router.push({name: 'myPage'})
        }
        this.$router.push(this.redirectUrl)
      }
    }
  }
}
</script>
