export default {
  imgPath: {
    teamLogo: 'https://s3.ap-northeast-2.amazonaws.com/crm.thesports.io/img/common/emblem/',
    couponIcon: 'https://gnfc-upload-file.s3.ap-northeast-2.amazonaws.com/'
  },
  setTeamLogo: function (num) {
    return `${this.imgPath.teamLogo}${num}.png`
  },
  setCouponIcon: function (name) {
    return `${this.imgPath.couponIcon}${name}.svg`
  }
}
