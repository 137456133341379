<template>
  <div>
    <div class="topMenu">
      <router-link :to="{name: 'myinfo'}" class="menu myinfo active">마이페이지</router-link>
      <a href=" https://www.gyeongnamfc.com/mypage/myinfo.php" target="_blank"  class="menu mypage">정보수정</a>
      <a href="https://www.gyeongnamfc.com/mypage/withdraw.php" target="_blank" class="menu withdrawal">회원탈퇴</a>
    </div>
    <div class="container">
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>마이페이지</strong></p>
      </div>
      <div class="mb30 mt30 myinfoClub">
        <p class="lineTitle">G멤버스 바코드</p>
        <div class="tileBox myinfoBox colorBlack">
          <QrCode level="L" :value="id"></QrCode>
          <p>{{id}}</p>
        </div>
      </div>
      <div class="mb30 myinfoClub">
        <p class="lineTitle">나의 G멤버스</p>
        <div class="tileBox myinfoBox colorBlack">
          <ul class="info">
            <li>
              <span class="label point mr10">G포인트</span>
              <span class="value">{{totalRate.point}}포인트</span>
            </li>
            <li>
              <span class="label money mr10">사용금액</span>
              <span class="value">{{totalPaymentAmount}}원</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api'
import regExp from '@/common/regExp'
import dayjs from 'dayjs'
import QrCode from 'qrcode.vue'

export default {
  name: 'Myinfo',
  components: {
    QrCode
  },
  created () {
    this.init()
    this.scrollToTop()
  },
  computed: {
    id () {
      return this.$store.state.user.id
    }
  },
  data () {
    return {
      totalRate: [],
      list: [],
      totalPaymentAmount: 0,
      point: 0,
      data: []
    }
  },
  methods: {
    init () {
      http.get('/ticket/game').then(res => {
        const totalRate = res.data.total
        const list = res.data.list
        const _tmp = []
        this.point = this.$store.state.user.point
        this.totalPaymentAmount = this.$store.state.user.userTotalPayment
        const _totalRate = {
          point: this.point,
          rate: totalRate.total_rate === null ? 0 : regExp.comma(totalRate.total_rate),
          total_payment_amount: this.totalPaymentAmount
        }
        list.map(row => {
          const _list = {
            title: row.away_team_name ? row.away_team_name : '정보 없음',
            isTicket: row.type === 'ticket',
            price: regExp.comma(row.total_payment_amount),
            result: '',
            gameDate: dayjs(row.game_date).format('MM.DD'),
            away_team_goal: row.away_team_goal,
            home_team_goal: row.home_team_goal,
            isWinPoint: row.win_point !== undefined
          }
          switch (row.win_point) {
            case 0:
              _list.result = '패배'
              break
            case 0.5:
              _list.result = '무승부'
              break
            case 1:
              _list.result = '승리'
              break
          }
          return _tmp.push(_list)
        })
        this.totalRate = _totalRate
        this.list = _tmp
      })
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
