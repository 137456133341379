import axios from 'axios'

/**
 * 개발서버 기준 환경변수
 */
const facilityPcBaseURL = process.env.VUE_APP_TICKETLINK_FACILITY_PC
const facilityMobileBaseURL = process.env.VUE_APP_TICKETLINK_FACILITY_MOBILE
const urlEncodedPartnerNo = process.env.VUE_APP_ENCODEDURI
/**
 * 예매팝업연동 - URL 생성
 * @param {String} encryptedId
 * @param {String} device
 *
 * device 파라미터 mobile이 아니면 pc버전 URL로 설정
 */
export function reservePopup (encryptedId, device) {
  if (device === 'mobile') {
    return `${facilityMobileBaseURL}/facility/direct/member/reserve?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`
  } else {
    return `${facilityPcBaseURL}/facility/direct/member/reserve?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`
  }
}

export function mypagePopup (encryptedId, device) {
  if (device === 'mobile') {
    return `${facilityMobileBaseURL}/facility/direct/member/mypage?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`
  } else {
    return `${facilityPcBaseURL}/facility/direct/member/mypage?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}`
  }
}

export function reserveDetailPopup (encryptedId, reserveNo, device) {
  // if (!encryptedId) {
  //   ret.success = false
  //   ret.message = '필수값 누락'
  //   return ret
  // }
  //
  // if (!reserveNo) {
  //   ret.success = false
  //   ret.message = '필수값 누락'
  //   return ret
  // }

  if (device === 'mobile') {
    return `${facilityMobileBaseURL}/facility/direct/member/detail?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}&reserveNo=${reserveNo}`
  } else {
    return `${facilityPcBaseURL}/facility/direct/member/detail?partnerNo=${urlEncodedPartnerNo}&memberIdEnc=${encodeURIComponent(encryptedId)}&reserveNo=${reserveNo}`
  }
}
