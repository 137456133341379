import Vue from 'vue'
import Vuex from 'vuex'

// 모든모듈 modules로 임포트
import * as modules from './modules/'
import http from '@/api'
import regExp from '@/common/regExp'

Vue.use(Vuex)
function getUserDataFromLocalStorage () {
  const userData = localStorage.getItem('userData')
  return userData ? JSON.parse(userData) : null
}

const store = new Vuex.Store({
  state: {
    login: false,
    signUp: false,
    user: getUserDataFromLocalStorage() || {
      name: '',
      point: 0,
      userTotalPayment: 0,
      id: ''
    },
    gameData: {
      totalRate: 0,
      totalPayment: 0
    },
    thisYear: '',
    lastYear: ''
  },
  mutations: {
    login (state, isLogin) {
      state.login = isLogin
      if (state.login === true) {
        store.dispatch('getUserInfo')
        store.dispatch('getUserGameData')
      }
    },
    signUp (state, isSignUp) {
      state.signUp = isSignUp
    },
    userInfo (state, payload) {
      state.user.name = payload.name
      state.user.point = regExp.comma(payload.point)
      state.user.userTotalPayment = regExp.comma(payload.userTotalPayment)
      state.user.id = payload.id
      localStorage.setItem('userData', JSON.stringify(state.user))
    },
    userGameData (state, payload) {
      state.gameData.totalRate = payload.totalRate
      state.gameData.totalPayment = regExp.comma(payload.totalPayment)
      localStorage.setItem('userGameData', JSON.stringify(state.gameData))
    },
    setThisYear (state, year) {
      state.thisYear = year
      state.lastYear = Number(year) - 1
    }
  },
  actions: {
    async getUserInfo ({commit}) {
      if (localStorage.getItem('token')) {
        const user = await http.get('/user')
        commit('userInfo', {
          name: user.data.data.user_name,
          point: user.data.data.point,
          userTotalPayment: user.data.data.total_payment_amount,
          id: user.data.data.user_id
        })
        getUserDataFromLocalStorage()
      }
    },
    async getUserGameData ({commit}) {
      if (localStorage.getItem('token')) {
        const data = await http.get('/ticket/game')
        commit('userGameData', {
          totalRate: data.data.total.total_rate,
          totalPayment: data.data.total_payment_amount
        })
      }
    }
  },
  getters: {
    isLogin: state => state.login,
    isSignUp: state => state.signUp
  },
  modules: modules.default
})

export default store
