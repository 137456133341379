<template>
  <div id="footer">
    <div class="links">
      <a class="link privacy colorRed" href="https://www.gyeongnamfc.com/member/privacy.php" target="_blank">개인정보처리방침</a>
      <span class="linkSpan">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <a class="link service" href="https://www.gyeongnamfc.com/member/terms.php" target="_blank">이용약관</a>
    </div>
    <ul class="bizInfoBox">
      <p class="name">주식회사 경남도민프로축구단</p>
      <li>
        <div class="dt">대표이사</div>
        <div class="dd">지현철</div>
      </li>
      <li>
        <div class="dt">주소</div>
        <div class="dd">경상남도 창원시 성산구 비음로 97, 1층(<span @click="test">사파</span>정동)</div>
      </li>
      <li>
        <div class="dt">사업자등록번호</div>
        <div class="dd">609-81-69085<br></div>
      </li>
      <li>
        <div class="dt">통신판매신고</div>
        <div class="dd">2005-경남창원-0170<a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6098169085" target="_blank" class="linkStyle">사업자정보확인</a></div>
      </li>
      <li>
        <div class="dt">개인정보처리책임자</div>
        <div class="dd">최한얼</div>
      </li>
      <li>
        <div class="dt">전화번호</div>
        <div class="dd"><a href="tel:0552832020"></a>055-283-2020</div>
      </li>
      <li>
        <div class="dt">팩스</div>
        <div class="dd">055-283-2992</div>
      </li>
        <li>
        <div class="dt">이메일</div>
        <div class="dd"><a href="mailto:gnfc_official@naver.com"></a>gnfc_official@naver.com</div>
      </li>
      <li>
        <div class="dt">호스팅사업자</div>
        <div class="dd">AWS(Amazon Web Service), 더스포츠커뮤니케이션</div>
      </li>
    </ul>
    <p class="copy">Copyright ⓒ GYEONGNAM FC All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {}
  },
  methods: {
    test () {
      // alert('test')
      document.location.href = 'https://gmembers.kr/ticket'
    }
  }
}
</script>
