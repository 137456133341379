<template>
  <div class="mainPage">
    <!-- main slider -->
    <div class="mainSlider">
      <carousel
        v-if="isLogin"
        :perPage="1"
        :paginationPadding="2"
        :autoplay="true"
        :autoplayTimeout="5000"
        :loop="true"
        ref="loginMain"
      >
        <slide
          v-for="(slide, index) in slider"
          v-if="slide.login_state === 'Y' || slide.login_state === 'S'"
          :key="index"
          :data-index="index"
          class="slide">
          <div class="sliderInner">
            <p v-if="slide.title" class="title" v-html="slide.title"></p>
            <p v-if="slide.content" class="desc" v-html="slide.content"></p>
            <a
              v-if="slide.link_type === 'Y'"
              :style="btnStyle(slide)"
              class="btn compact round bold"
              :href="slide.link"
              :target="slide.link_new_window === 'Y' ? '_blank' : '_self'"
              v-html="slide.btn_title"
            ></a>
            <a
              v-if="slide.link_type === 'N'"
              :style="btnStyle(slide)"
              class="btn compact round bold"
              :href="slide.link"
              :target="slide.link_new_window === 'Y' ? '_blank' : '_self'"
              v-html="slide.btn_title"
            ></a>
            <div class="pageNum">
              <p><strong>{{slide.pagenum}}</strong> / {{allPageNum}}</p>
            </div>
          </div>
          <div class="bg"><img :src="slide.img" :style="imgStyle(slide)" :alt="slide.title || ''" /></div>
        </slide>
      </carousel>
      <carousel
        v-else
        :perPage="1"
        :paginationPadding="2"
        :autoplay="true"
        :autoplayTimeout="5000"
        :loop="true"
        ref="logoutMain"
      >
        <slide
          v-for="(slide, index) in slider"
          v-if="slide.login_state === 'N' || slide.login_state === 'S'"
          :key="index"
          :data-index="index"
          class="slide">
          <div class="sliderInner">
            <p v-if="slide.title" class="title" v-html="slide.title"></p>
            <p v-if="slide.content" class="desc" v-html="slide.content"></p>
            <router-link
              v-if="slide.link_type === 'Y'"
               :style="btnStyle(slide)"
               class="btn compact round bold"
              :to="slide.link"
              v-bind="{ target: slide.link_new_window === 'Y' ? '_blank' : '_self' }"
              v-html="slide.btn_title"
            ></router-link>
            <span
            v-if="slide.link_type ==='N'"
            :style="btnStyle(slide)"
            class="btn compact round bold"
            >{{slide.btn_title}}</span>
            <div class="pageNum">
              <p><strong>{{slide.pagenum}}</strong> / {{allPageNum}}</p>
            </div>
          </div>
          <div class="bg"><img :src="slide.img" :style="imgStyle(slide)" :alt="slide.title || ''" /></div>
        </slide>
      </carousel>
    </div>
    <!-- //mains slider -->
    <!--  my Gmembers (로그인시에만보임) -->
    <div class="sectionBox mainLinks" v-if="isLogin">
      <div class="myinfoClub">
        <p class="lineTitle">나의 G멤버스</p>
        <div class="tileBox myinfoBox colorBlack">
          <ul class="info">
            <li>
              <span class="label point mr10">G포인트</span>
              <span class="value">{{point}}포인트</span>
            </li>
            <li>
              <span class="label money mr10">사용금액</span>
              <span class="value">{{totalPaymentAmount}}원</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
      </div>
    </div>
    <!--  //my Gmembers  -->
    <!-- match schedule -->
    <div class="sectionBox mainLinks">
      <div class="titleBox">
        <span class="lineTitle">MATCH SCHEDULE</span>
        <a href="https://www.gyeongnamfc.com/match/match.php" target="_blank">더보기</a>
      </div>
      <carousel
        :perPage="1"
        :paginationActiveColor="'#515257'"
        :pagination-color="'rgba(81, 82, 87, 0.2)'"
        :paginationPadding="2"
        :navigationEnabled="false"
        ref="myCarousel"
      >
        <slide
          v-for="(slide, index) in matchList"
          :key="index"
          :data-index="index"
          class="slide">
          <div class="sliderInner">
            <div class="matchBox">
              <div class="matchBoxTop">
                <div class="matchBoxLeft">
                  <img :src="slide.set_home_logo">
                  <p>{{slide.home_team_name}}</p>
                </div>
                <div class="matchBoxMiddle">
                  <p v-if="slide.audience === 0">VS</p>
                  <p v-else>{{slide.home_team_goal}} : {{slide.away_team_goal}}</p>
                </div>
                <div class="matchBoxRight">
                  <img :src="slide.set_away_logo">
                  <p>{{slide.away_team_name}}</p>
                </div>
              </div>
              <div class="matchBoxBottom">
                <p class="time">{{slide.set_game_date}}({{slide.yoil}}) {{slide.set_game_time}}</p>
                <p class="place">@{{slide.field_name}}</p>
              </div>
              <div v-show="slide.audience === 0 && slide.home_team === 'K20'">
                <router-link :to="{name: 'ticket'}" class="ticketBtn">티켓예매</router-link>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
    <!-- // match schedule -->
    <!-- links -->
    <div class="sectionBox mainLinks" style="padding-top: 0">
      <p class="lineTitle">LINK</p>
      <div>
        <a v-for="(link, index) in links"
          :key="index"
          :href="link.link"
           :target="link.link_new_window === 'N' ? '_self' : '_blank'"
          class="btn full LinkBtn">
          <img v-if="link.icon_type === 'image'" :src="link.icon">
          <span v-if="link.icon_type === 'emoji'" v-html="link.icon" class="emoji"></span>
          {{link.title}}
        </a>
        <div ref="carousel" class="mt20">
          <carousel
            :perPage="1"
            :paginationPadding="2"
            :pagination-enabled="false"
            :autoplay="true"
            :autoplayTimeout="3000"
            :loop="true"
            :mouse-drag="true"
            :navigationEnabled="false">
            <slide
              v-for="(slide, index) in eventBanner"
              :key="index"
              :data-index="index"
              class="slide">
              <div class="bg">
                <a :href="slide.link" :target="slide.link_new_window === 'N' ? '_self' : '_blank'">
                  <img
                    style="width: -webkit-fill-available; object-fit: fill; margin-right: 0; height: 150px;border-radius: 10px;"
                    :src="slide.image" :alt="slide.title || ''" />
                </a>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
    <!-- //links -->
    <!-- channel -->
    <div class="sectionBox mainChannel">
      <p class="lineTitle">CHANNEL</p>
      <!-- 탭 -->
      <div class="tabs">
        <div class="tabBox">
          <button v-for="tab in tabs" :key="tab.id" type="button" class="btnTab" :class="{ active: tab.id === current }"
                  :data-tab="tab.id" @click="feedTab(tab.id)">
            {{ tab.name }}
          </button>
        </div>
      </div>
      <!-- //탭 -->
      <div class="channels">
          <div v-for="(data, index) in visibleImages" :key="index" class="imgBox">
              <a :href="data.link_url || '#'" target="_blank">
                <img :src="data.thumbnail_img">
              </a>
          </div>
      </div>
      <div v-if="showButton">
        <button v-if="feedsList.length > 4" @click="showMore" class="viewMore">더보기</button>
      </div>
    </div>
    <!-- //channel -->
    <!-- club -->
    <div class="sectionBox colorBg mainClub">
      <div class="inner">
        <div class="innerText">
          <div class="logo"></div>
          <p class="lineTitle"><strong>G</strong> MEMBERS</p>
          <p>경남FC 팬을 위한 공간 'G멤버스'<br>나의 관람 정보를 확인하고 홈경기 혜택을 받아보세요!
          </p>
        </div>
        <div class="lineBtns">
          <router-link
            v-for="(link, index) in clubFilter" :key="index"
            :to="{ name: link.link }"
            :data-club="link.id"
            class="lineBtn"><span class="bold ml10">{{link.club}}</span></router-link>
        </div>
      </div>
    </div>
    <!-- //club -->
  </div>
</template>

<script>
import http from '@/api/index'
import regExp from '@/common/regExp'
import dayjs from 'dayjs'
import imgPath from '@/library/imgPath'

export default {
  name: 'Main',
  props: [
    'isLogin'
  ],
  created () {
    this.init()
    this.initChannel()
    if (localStorage.getItem('token')) {
      this.initLoginData()
    }
    console.log(this.setLogin)
  },
  computed: {
    setLogin () {
      if (this.isLogin) {
        return 'Y'
      } else {
        return 'N'
      }
    },
    point () {
      return this.$store.state.user.point
    },
    totalPaymentAmount () {
      return this.$store.state.user.userTotalPayment
    },
    rate () {
      const rate = this.$store.state.gameData.totalRate
      return rate === null ? 0 : regExp.comma(rate)
    },
    clubFilter () {
      const club = this.club
      const isLogin = this.isLogin
      return club.filter(x => {
        return !(x.id === 'logout' && !isLogin) &&// 미로그인상태에서 로그아웃 X
          !(x.id === 'login' && isLogin) // 로그인상태에서 로그인 X
      })
    },

    feedsList () {
      const tab = this.current
      const validTab = this.tabs.map(x => x.id)
      let feeds
      if (tab === 'all') {
        feeds = this.allList
      }
      if (tab === 'news') {
        feeds = this.newsList
      }
      if (tab === 'instagram') {
        feeds = this.instagramList
      }
      if (tab === 'youtube') {
        feeds = this.youtubeList
      }
      // eslint-disable-next-line array-callback-return
      const list = feeds.filter(x => {
        if (tab === 'all') {
          return x
        } else {
          if (x.type === tab) {
            return x
          }
        }
      })
      return list
    },
    visibleImages () {
      // imagesToShow 변수에 따라서 보여질 이미지들을 필터링
      return this.feedsList.slice(0, this.imagesToShow)
    }
  },
  data () {
    return {
      userName: '',
      userPoint: 0,
      totalRate: 0,
      slider: [],
      links: [],
      feeds: [],
      allList: [],
      youtubeList: [],
      instagramList: [],
      newsList: [],
      eventBanner: [],
      current: 'all',
      imagesToShow: 4, // 클릭하기 전에 표시할 이미지 수
      showButton: true, // '더 보기' 버튼을 표시할지 여부,
      loginPageNum: 0,
      logoutPageNum: 0,
      allPageNum: 0,
      matchList: [],
      gmambersTotalRate: {},
      channel: [
        { id: 'homepage', channel: '홈페이지', link: 'https://www.gyeongnamfc.com/main.php' },
        { id: 'youtube', channel: '유튜브', link: 'https://www.youtube.com/channel/UCTvoVkwegdQSCMX4PT06rpg' },
        { id: 'instagram', channel: '인스타그램', link: 'https://www.instagram.com/gyeongnamfc/' },
        { id: 'facebook', channel: '페이스북', link: 'https://www.facebook.com/GyeongnamFC' }
      ],
      tabs: [
        { id: 'all', name: '전체', link: '/', active: true },
        { id: 'news', name: '구단뉴스', link: '/', active: false },
        { id: 'youtube', name: '유튜브', link: '/', active: false },
        { id: 'instagram', name: '인스타그램', link: '/', active: false }
      ],
      club: [
        { id: 'login', club: '로그인', link: 'login' },
        { id: 'logout', club: '로그아웃', link: 'logout' },
        { id: 'ticket', club: '티켓예매', link: 'ticket' },
        { id: 'attendance', club: '출석체크', link: 'attendance' },
        { id: 'reservation', club: '예매내역 조회', link: 'reservationList' },
        { id: 'event', club: '이벤트 신청', link: 'eventHistory' },
        { id: 'membership', club: '시즌권', link: 'membership' },
        { id: 'survey', club: '설문조사 참여', link: 'surveyHistory' },
        { id: 'point', club: 'G포인트 조회', link: 'pointHistory' },
        { id: 'stadium', club: '경기장 안내', link: 'stadium'},
        { id: 'coupon', club: '쿠폰 조회', link: 'couponHistory' }
      ]
    }
  },
  methods: {
    init () {
      // 메인배너
      http.get('/main/slide').then(res => {
        if (!localStorage.getItem('token')) {
          this.$store.state.login = false
        }
        this.slider = res.data
        this.slider.map(el => {
          // eslint-disable-next-line no-constant-condition
          if (el.login_state === 'N' || el.login_state === 'S') {
            el.pagenum = ++this.logoutPageNum
          }
          // eslint-disable-next-line no-constant-condition
          if (el.login_state === 'Y' || el.login_state === 'S') {
            el.pagenum = ++this.loginPageNum
          }
          el.target = 'self'
          if (el.link === '/join') {
            el.link = 'https://www.gyeongnamfc.com/member/signup_1.php'
            el.target = '_blank'
          }
          return el
        })
        this.isLogin ? this.allPageNum = this.loginPageNum : this.allPageNum = this.logoutPageNum
      })
      // 메인링크
      http.get('/main/link').then(res => {
        if (res && res.data) {
          this.links = res.data.filter(el => el.login_state === this.setLogin)
        }
      })
      // 메인매치
      http.get('/game').then(res => {
        const data = res.data.data
        let selectedNextMatch
        let selectedLastMatch
        if (data.future_game.length === 0) {
          this.$refs.myCarousel.navigateTo = 0
          selectedLastMatch = data.past_game.slice(-3)
          this.matchList.push(...selectedLastMatch)
        }
        if (data.future_game.length === 1) {
          this.$refs.myCarousel.navigateTo = 1
          selectedNextMatch = data.future_game.slice(0, 1)
          selectedLastMatch = data.past_game.slice(-2)
          this.matchList.push(...selectedLastMatch, ...selectedNextMatch)
        }
        if (data.future_game.length > 1 && data.past_game.length > 0) {
          this.$refs.myCarousel.navigateTo = 1
          selectedNextMatch = data.future_game.slice(0, 2)
          selectedLastMatch = data.past_game.slice(-1)
          this.matchList.push(...selectedLastMatch, ...selectedNextMatch)
        }
        if (data.future_game.length > 1 && data.past_game.length === 0) {
          this.$refs.myCarousel.navigateTo = 1
          selectedNextMatch = data.future_game.slice(0, 3)
          this.matchList.push(...selectedNextMatch)
        }
        this.matchList.map(el => {
          el.set_game_date = dayjs(el.game_date).format('YYYY.MM.DD')
          el.set_game_time = el.game_time.slice(0, 5)
          if (el.home_team === 'K07') {
            el.home_team = 'K07_new'
          }
          if (el.away_team === 'K07') {
            el.away_team = 'K07_new'
          }
          el.set_home_logo = imgPath.setTeamLogo(el.home_team)
          el.set_away_logo = imgPath.setTeamLogo(el.away_team)
          return el
        })
      })
      // 이벤트 이미지 배너
      http.get('/main/banner').then(res => {
        const eventBanner = res.data
        this.eventBanner = eventBanner.filter(el => el.login_state === this.setLogin)
      })
    },
    async initChannel () {
      const arr = []
      // 전체
      const allList = await http.get('/notice/news')
      allList.data.data.map(el => {
        el.type = 'all'
        el.setTime = el.w_date
        return el
      })
      this.allList = allList.data.data
      // 구단뉴스
      const newsList = await http.get('/notice/news?type=news')
      newsList.data.data.map(el => {
        el.type = 'news'
        el.setTime = el.w_date
        return el
      })
      this.newsList = newsList.data.data
      // 유튜브
      const youtubeList = await http.get('/notice/news?type=youtube&page=1')
      youtubeList.data.data.map(el => {
        el.type = 'youtube'
        el.setTime = el.reg_date
        return el
      })
      this.youtubeList = youtubeList.data.data
      // 인스타그램
      const instagramList = await http.get('/notice/news?type=instagram&page=1')
      instagramList.data.data.map(el => {
        el.type = 'instagram'
        el.setTime = el.reg_date
        return el
      })
      this.instagramList = instagramList.data.data
    },
    btnStyle (data) { // 추후 말씀드리고 수정할 것
      const result = {}
      if (data.btn_color) {
        result.backgroundColor = '#' + data.btn_color
        // result.backgroundColor = '#EF4034'
      }
      if (data.btn_txt_color) {
        result.color = '#' + data.btn_txt_color
        // result.color = '#212121'
      }
      if (!data.btn_title) {
        result.display = 'none'
      }
      if (result.title) {
        result.display = 'block'
      }
      return result
    },
    imgStyle (data) {
      const result = {}
      if (data.btn_title === '' && data.content === '') {
        result.opacity = 1
      }
      return result
    },
    status (id) {
      let result = true
      const isLogin = this.isLogin
      if (id === 'join' && isLogin) {
        result = false
      } else if ((id === 'mypage' || id === 'logout') && !isLogin) {
        result = false
      }
      return result
    },
    feedTab (type) {
      this.current = type
      this.imagesToShow = 4
      this.showButton = true // 더 보기 버튼을 숨김
    },
    showMore () {
      this.imagesToShow = this.feedsList.length
      this.showButton = false // 더 보기 버튼을 숨김
    },
    initLoginData () {
      try {
        this.$store.dispatch('getUserInfo')
      } catch (error) {
        console.error('main dispatch getUserInfo', error)
      }
      try {
        this.$store.dispatch('getUserGameData')
      } catch (error) {
        console.error('main dispatch getUserGameData', error)
      }
    }
  }
}
</script>
