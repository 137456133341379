<template>
  <div id="header">
    <div class="gnb">
      <router-link :to="{name: 'myinfo'}" class="mapage"></router-link>
      <div class="logoBox" @click="scrollToTop"><router-link :to="{name: 'main'}" class="logo"></router-link></div>
      <button type="button" :class="{btnMenu: !open, btnClose: open}" @click="toggleMenu"></button>
    </div>
    <div v-if="open" id="menu" class="sectionBox colorBg mainClub scroll">
      <div class="inner">
        <div class="innerText">
          <div class="logo"></div>
          <p class="lineTitle"><strong>G MEMBERS</strong></p>
          <p>경남FC 팬을 위한 공간 'G멤버스'<br>나의 관람 정보를 확인하고 홈경기 혜택을 받아보세요!
          </p>
        </div>
        <div class="lineBtns" @click="toggleMenu">
          <router-link
            v-for="(link, index) in clubFilter"
            :key="index"
            :to="{name: link.link}"
            :data-club="link.id"
            class="lineBtn">
            <span class="bold ml10">{{link.club}}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: [
    'isLogin'
  ],
  computed: {
    clubFilter () {
      const club = this.club
      const isLogin = this.isLogin
      return club.filter(x => {
        return !(x.id === 'logout' && !isLogin) &&// 미로그인상태에서 로그아웃 X
          !(x.id === 'login' && isLogin) // 로그인상태에서 로그인 X
      })
    },
    user () {
      const isLogin = this.isLogin
      if (isLogin) {
        return this.$store.state.user.name
      }
      return null
    },
    point () {
      const isLogin = this.isLogin
      if (isLogin) {
        return this.$store.state.user.point
      }
      return null
    },
    rate () {
      const isLogin = this.isLogin
      if (isLogin) {
        if (this.$store.state.gameData.totalRate !== null) {
          return this.$store.state.gameData.totalRate
        } else {
          return 0
        }
      }
      return null
    }
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        this.open = false // 라우터 전환 시 메뉴 닫기
      }
    }
  },
  data () {
    return {
      userName: '',
      userPoint: 0,
      totalRate: 0,
      payment_amount: 0,
      open: false,
      club: [
        { id: 'login', club: '로그인', link: 'login' },
        { id: 'logout', club: '로그아웃', link: 'logout' },
        { id: 'ticket', club: '티켓예매', link: 'ticket' },
        { id: 'attendance', club: '출석체크', link: 'attendance' },
        { id: 'reservation', club: '예매내역 조회', link: 'reservationList' },
        { id: 'event', club: '이벤트 신청', link: 'eventHistory' },
        { id: 'membership', club: '시즌권', link: 'membership' },
        { id: 'survey', club: '설문조사 참여', link: 'surveyHistory' },
        { id: 'point', club: 'G포인트 조회', link: 'pointHistory' },
        { id: 'stadium', club: '경기장 안내', link: 'stadium'},
        { id: 'coupon', club: '쿠폰 조회', link: 'couponHistory' }
      ]
    }
  },
  methods: {
    toggleMenu () {
      const toggle = this.open ? 'remove' : 'add'
      document.body.classList[toggle]('hiddenBody')
      this.open = !this.open
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
