/**
 * addMembership_terms: 시즌권 개인정보 수집 및 이용 안내 (AddMembership)
 * addSurvey_terms: 설문조사 개인정보 수집 및 이용 안내 (Survey)
 */

export default {
  addMembership_terms: `
(주)경남도민프로축구단은 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.<br>
<br>
1. 수집한 개인정보의 이용<br>
• 이용자 식별, 원활한 의사소통<br>
• 행사 시 정보 전달 및 민원 처리<br>
• 시즌권 이용 정보 전달<br>
<br>
2. 수집하는 개인정보 항목<br>
아이디, 이름, 연락처, 시즌권 번호<br>
<br>
3. 개인정보의 보유기간 및 이용기간<br>
수집된 정보는 회원 탈퇴 시 혹은 회원의 요청 시 개인정보는 삭제됩니다.<br>
<br>
4. 동의 거부 권리 및 동의 거부 시 불이익 내용<br>
동의 거부 시 시즌권을 등록하실 수 없습니다.<br>
  `,
  addSurvey_terms: `
1. 수집한 개인정보의 이용<br>
• 이용자 식별, 원활한 의사소통<br>
• 경남FC 경기 관람 만족도 확인<br>
<br>
2. 수집하는 개인정보 항목<br>
아이디, 이름, 연락처<br>
<br>
3. 개인정보의 보유기간 및 이용기간<br>
수집된 정보는 설문조사 종료 후 1년까지 보관되며 그 이후에는 지체없이 파기됩니다.<br>
<br>
4. 동의 거부 권리 및 동의 거부 시 불이익 내용<br>
동의 거부 시 설문조사를 제출할 수 없습니다.
`,
  fixEvent_terms: `
1. 수집한 개인정보의 이용<br>
- 이용자 식별, 원활한 의사소통<br>
- 행사 시 정보 전달 및 민원 처리<br>
<br>
2. 수집하는 개인정보 항목<br>
아이디, 이름, 연락처, 경기선택, 단체명(이름), 신청인원, 신청사연, 첨부파일<br>
<br>
3. 개인정보의 보유기간 및 이용기간<br>
수집된 정보는 이벤트 종료 후 이벤트 당첨자의 이벤트 참여 완료 된 이후 지체없이 파기됩니다.<br>
<br>
4. 동의 거부 권리 및 동의 거부 시 불이익 내용<br>
동의 거부 시 이벤트에 참가할 수 없습니다.<br>
  `
}
