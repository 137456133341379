<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>설문조사</strong></p>
    </div>
    <div class="btns mb20">
      <button
        v-for="(duration, idx) in filterOptions"
        :key="idx"
        type="button"
        class="btn componentTab"
        :class="{ btnGrey: filter !== duration.id }"
        @click="setFilter(duration.id)"
      >
        {{ duration.name }}
      </button>
    </div>
    <div class="historyList">
      <div class="detailBox">
        <div class="box" v-for="(item, i) in list" :key="i">
          <div class="mainBox">
            <div class="titleBox">
              <div class="logo mr10">
                <img class="img" :src="item.logo"></div>
              <div class="content leftText">
                <p class="title"><strong>{{item.title}}</strong></p>
                <p class="longTime fw400 colorLightGrey">{{item.from}}~{{item.to}}
                  <p class="fw400">{{item.num}}</p>
                  <div class="alert">
                  <span class="alertBox mouseCursor"
                        :class="item.setBtn"
                        @click="goSurvey(item.id, item.isSurvey)">{{item.isSurvey}}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <eagle-box
        v-show="!isList"
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :is-btn="btnTmp.isBtn"
      />
    </div>
  </div>
</template>

<script>

import api from '@/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import EagleBox from '@/common/EagleBox.vue'
import imgPath from '@/library/imgPath'
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

export default {
  name: 'History',
  components: {EagleBox},
  props: [
    'isLogin'
  ],
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      thisYear: '',
      lastYear: '',
      filter: '', // 초기 필터 값 설정
      filterOptions: [
        {id: '', name: '진행중'},
        {id: 'end', name: '종료'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      list: [],
      btnTmp: {
        title: '해당 기간, 조건에 해당하는 설문조사가 없습니다.',
        subTitle: '기간 변경하시거나, 설문조사를 기다려주세요!',
        isBtn: false
      },
      isList: true,
      term: 1,
      target_number: ''
    }
  },
  created () {
    this.thisYear = this.$store.state.thisYear
    this.lastYear = this.$store.state.lastYear
  },
  methods: {
    async fetchData () {
      const date = {type: '', num: ''}
      date.type = this.filter
      if (this.filter === 'thisYear') {
        date.type = 'year'
        date.num = this.thisYear
      }
      if (this.filter === 'lastYear') {
        date.type = 'year'
        date.num = this.lastYear
      }
      /**
       * NE:미접근
       * NA:미답변
       * C:완료*/
      api.get(`/survey?type=${date.type}&year=${date.num}`).then(res => {
        this.list = []
        const data = res.data
        const _tmp = []
        const nowTime = dayjs()
        for (const row of data) {
          if (row.state !== 'N') {
            const parsedTime = new Date(row.end_date)
            const _parsedTime = new Date(row.reserve_date)
            const hours = parsedTime.getUTCHours().toString().padStart(2, '0')
            const minutes = parsedTime.getUTCMinutes().toString().padStart(2, '0')
            const _hours = _parsedTime.getUTCHours().toString().padStart(2, '0')
            const _minutes = _parsedTime.getUTCMinutes().toString().padStart(2, '0')
            row.end_date_time = `${hours}:${minutes}`
            row.reserve_date_time = `${_hours}:${_minutes}`
            const _row = {
              id: row.idx,
              title: row.subject,
              from: `${dayjs.utc(row.reserve_date).format('YYYY.MM.DD')} ${row.reserve_date_time}`,
              to: `${dayjs.utc(row.end_date).format('YYYY.MM.DD')} ${row.end_date_time}`,
              expire: false,
              chkDate: false,
              logo: row.away_team ? imgPath.setTeamLogo(row.away_team) : imgPath.setCouponIcon('point_basic')
            }
            if (row.state !== 'C') {
              _row.state = 'N'
              _row.setBtn = 'BgRed'
              _row.isSurvey = '설문 참여'
            } else {
              _row.state = 'Y'
              _row.setBtn = 'red'
              _row.isSurvey = '참여완료'
            }
            if (row.state === 'NA' && dayjs(row.end_date).isSameOrBefore(dayjs())) {
              _row.chkDate = true
              _row.setBtn = 'mdGray'
              _row.isSurvey = '설문 마감'
            }
            if (date.type === 'end' || date.type === 'year') {
              if (row.state === 'C') {
                _row.state = 'Y'
                _row.setBtn = 'red'
                _row.isSurvey = '참여완료'
              } else {
                _row.chkDate = true
                _row.setBtn = 'mdGray'
                _row.isSurvey = '설문 마감'
              }
            }
            const setEndDate = dayjs(row.end_date)
            if (nowTime.isSameOrBefore(setEndDate, 'day') === true) {
              _row.expire = true
            }
            _tmp.push(_row)
          }
        }
        this.list = _tmp
        this.isList = this.list.length !== 0
      })
    },
    setFilter (duration) {
      this.filter = duration
      this.fetchData()
    },
    goSurvey (id, type) {
      if (type === '참여완료') {
        alert('참여 완료된 설문조사입니다.')
        return
      }
      if (type === '설문 마감') {
        alert('마감된 설문조사입니다.')
        return
      }
      this.$router.push({ name: 'surveyTarget', params: { id: id } })
    }
  }
}
</script>
