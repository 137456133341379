<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>예매 내역</strong></p>
    </div>
    <div class="btns mb20">
      <button
        v-for="(duration, idx) in filterOptions"
        :key="idx"
        type="button"
        class="btn componentTab"
        :class="{ btnGrey: filter !== duration.id }"
        @click="setFilter(duration.id)"
      >
        {{ duration.name }}
      </button>
    </div>
    <div class="historyList">
      <div class="detailBox">
        <div class="box" v-for="(item, i) in list" :key="i">
          <div class="mainBox">
            <div class="titleBox">
              <div class="logo mr10">
                <img class="img" :src="item.set_logo"></div>
              <div class="content leftText">
                <p><strong>vs {{item.away_team_name}}</strong></p>
                <p class="fw400">경기일시 : {{item.set_game_date}}</p>
                <p class="fw400">예매번호 : {{item.reserve_no}}</p>
                <div class="alert">
                  <span class="mr5 alertBox" :class="item.btnState">{{item.cancle_state}}</span>
                  <button class="alertBox BgRed" v-show="item.onCancle" @click="goTicket">예매취소</button>
                  <button class="alertBox BgRed ml5" v-if="item.onCancle" @click="goTicketDetail(item.reserve_no)">스마트티켓</button>
                </div>
              </div>
              <div class="arrowBtn" @click="item.open = !item.open">
                <img :src="item.open ? require('@/assets/img/ico_down.png') : require('@/assets/img/ico_up.png')">
              </div>
            </div>

          </div>
          <div class="subBox" v-if="item.open">
            <div
                class="listBox"
                 v-for="(el, idx) in item.detail"
                 :key="idx"
            >
              <div class="listTmp" :data-channel="'refrash'"><strong>{{el.seat_Attribute_4}} {{el.seat_Attribute_5}} {{el.seat_Attribute_6}}</strong></div>
              <div class="listTmp" :data-channel="'age'"><strong>{{el.product_denomination_name}}</strong></div>
              <div class="listTmp" :data-channel="'ticket'">{{el.ticket_no}}</div>
              <div class="cancel" v-if="el.cancel_yn === 'Y'"><span class="alertBox mdGray">취소완료</span></div>
            </div>
          </div>
        </div>
      </div>
      <eagle-box
        v-show="!isList"
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :is-btn="btnTmp.isBtn"
        :btn-type="btnTmp.btnType"
        :btn-name="btnTmp.btnName"
      />
    </div>
    <div class="mt30">
      <p class="lineTitle">예매내역 안내사항</p>
      <p class="mb10 fz14 light colorSemiBlack" v-html="couponText">
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import regExp from '@/common/regExp'
import EagleBox from '@/common/EagleBox.vue'
import imgPath from '@/library/imgPath'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import {mypagePopup, reserveDetailPopup} from '@/library/ticketlink'
dayjs.locale('ko')

export default {
  name: 'History',
  components: {EagleBox},
  computed: {
    name () {
      return this.$store.state.user.name
    }
  },
  created () {
    this.thisYear = this.$store.state.thisYear
    this.lastYear = this.$store.state.lastYear
    this.fetchData()
  },
  data () {
    return {
      thisYear: '',
      lastYear: '',
      filter: '1', // 초기 필터 값 설정
      filterOptions: [
        {id: '1', name: '1개월'},
        {id: '3', name: '3개월'},
        {id: '6', name: '6개월'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      type: '',
      list: [],
      term: 1,
      btnTmp: {
        title: '',
        subTitle: '예매를 통해서 경남FC와 함께해주세요!',
        isBtn: true,
        btnName: '티켓예매',
        btnType: 'ticket'
      },
      isList: true,
      encryptedId: '',
      couponText: `
       - 예매내역은 예매 직후 확인이 안 될 경우, 10분 뒤 확인해 주시면 됩니다.
      `
    }
  },
  methods: {
    setFilter (duration) {
      this.filter = duration
      this.fetchData()
    },
    fetchData () {
      /**
       * 예매완료 : gray
       * 예매취소 : bgRed
       * 취소완료 : red
       * 부분취료 : red
       * 관람완료 : mdGray
       * */
      const date = {type: 'month', num: '1'}
      date.num = this.filter
      if (this.filter === 'thisYear') {
        date.type = 'year'
        date.num = this.thisYear
      }
      if (this.filter === 'lastYear') {
        date.type = 'year'
        date.num = this.lastYear
      }

      api.get(`/ticket?type=${date.type}&term=${date.num}`)
        .then(res => {
          const list = res.data.data
          list.map(row => {
            row.open = false
            row.onCancle = false // 예매취소버튼
            row.set_logo = imgPath.setTeamLogo(row.away_team)
            row.set_game_date = `${dayjs(row.game_date).format('YYYY.MM.DD')} (${dayjs(row.game_date).format('ddd')}) ${row.game_time.substring(0, 5)}`
            row.price = regExp.comma(row.total_payment_amount)
            const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
            row.set_date = `${dayjs(row.game_date).format('YYYY-MM-DD')} ${row.game_time}`
            if (row.detail) {
              const cancelStates = row.detail.map(detail => detail.cancel_yn)
              if (cancelStates.includes('N') && cancelStates.includes('Y') && now < row.set_date) {
                row.cancle_state = '부분취소'
                row.btnState = 'red'
                row.onCancle = true
              }
              if (cancelStates.every(state => state === 'N') && now < row.set_date) {
                row.cancle_state = '예매완료'
                row.btnState = 'gray'
                row.onCancle = true
              }
              if (cancelStates.every(state => state === 'N') && now >= row.set_date) {
                row.cancle_state = '관람완료'
                row.btnState = 'mdGray'
              }
              if (cancelStates.includes('N') && cancelStates.includes('Y') && now >= row.set_date) {
                row.cancle_state = '관람완료'
                row.btnState = 'mdGray'
              }
              if (cancelStates.every(state => state === 'Y')) {
                row.cancle_state = '취소완료'
                row.btnState = 'red'
              }
            }
            return row
          })
          this.list = list
          if (this.list.length === 0) {
            this.btnTmp.title = `${this.name}님, 해당 기간내 예매내역이 없습니다!`
            this.isList = false
          } else {
            this.isList = true
          }
        })
    },
    copy (num) {
      const el = document.createElement('textarea')
      document.body.appendChild(el)
      el.value = num
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      alert('클립보드에 복사되었습니다.')
    },
    goTicket () {
      this.$router.push({name: 'ticket'})
    },
    goTicketDetail (reserveNo) {
      api.get('/user/ticketlink_id')
        .then(res => {
          this.encryptedId = res.data.data.id
          if (this.encryptedId !== '') {
            window.open(reserveDetailPopup(this.encryptedId, reserveNo), 'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
