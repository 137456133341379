<template>
  <div></div>
</template>

<script>
import http from '@/api'

export default {
  name: 'Logout',
  created () {
    http.post('/account/logout')
      .then(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('userData')
        this.$store.commit('login', false)
        this.$router.push({
          name: 'main'
        })
      })
  },
  data () {
    return {}
  }
}
</script>
