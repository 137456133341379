export default {
  name: 'regExp',
  htmlCommentSingle: /<!--(.*?)-->/gm,
  naturalNumber: /^[1-9]\d*$/,
  expEmail: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
  expPhone: /^\d{2,3}-\d{3,4}-\d{4}$/,
  expId: /(?=.*\d)(?=.*[a-z]).{6,20}/,
  expPassword: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,20}$/,
  regPhone: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
  imageFormat: /(png|jpeg|svg|jpg|gif)$/,
  numberOnly: function (num) {
    return num.toString().replace(/[^0-9.]/g, '')
  },
  comma: function (num) {
    const reg = /^([-+]?\d+)(\d{3})(\.\d+)?/
    let string = '' + (num != null ? num : '')
    string = string.replace(/[^-+.\d]/g, '')
    while (reg.test(string)) string = string.replace(reg, '$1' + ',' + '$2' + '$3')
    return string
  },
  uncomma: function (str) {
    str = String(str)
    return str.replace(/[^-.\d]/g, '')
  },
  replaceAllEmpty: function (str) {
    return str.replace(/(\s*)/g, '').toLowerCase()
  },
  maskingName: function (str) {
    const replace = function (str, index, char) {
      return str.substr(0, index) + char + str.substr(index + char.length)
    }
    const index = parseInt(str.length / 2)
    return replace(str, index, '*')
  },
  maskingId: function (str) {
    const replace = function (str, length, char) {
      const result = []
      for (let i = 0; i < str.length; i++) {
        result.push(i < str.length - length ? str[i] : char)
      }
      return result.join('')
    }
    return replace(str, 5, '*')
  },
  maskingPhone: function (phone) {
    phone = phone.split('-')
    const replace = function (str, char) {
      return str.replace(/[0-9]/g, char)
    }
    return `${phone[0]}-${replace(phone[1], '*')}-${phone[2]}`
  }
}
