<template>
    <div class="container">
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>포인트 조회</strong></p>
      </div>
      <div class="btns mb20">
        <button
          v-for="(duration, idx) in filterOptions"
          :key="idx"
          type="button"
          class="btn componentTab"
          :class="{ btnGrey: filter !== duration.id }"
          @click="setFilter(duration.id)"
        >
          {{ duration.name }}
        </button>
      </div>
      <div class="historyList mb30">
        <div class="detailBox">
          <div class="box" v-for="(item, i) in list" :key="i">
            <div class="mainBox">
              <div class="titleBox">
                <div class="mr5 logo">
                  <img class="attendance"
                       :src="item.icon !== ''
                       ? item.icon
                       : require('@/assets/img/K06_dummy.png')"></div>
                <div class="content leftText">
                  <p><strong>{{item.title}}</strong></p>
                  <p class="fw400 mt5">{{item.content}}</p>
                  <p class="colorLightGrey mt5">{{item.date}}</p>
                </div>
                <div class="alert attendance">
                  <span class="point"
                        :class="item.state!=='minus'
                        ? 'colorRed'
                        : 'colorGrey'">
                          {{item.setPoint}}</span>
                </div>
              </div>

            </div>
            <div class="subBox" v-if="item.open">
              <div
                class="listBox"
                v-for="(el, idx) in item.detail"
                :key="idx"
              >
                <div class="listTmp" :data-channel="'refrash'"><strong>{{el.seat}}</strong></div>
                <div class="listTmp" :data-channel="'age'"><strong>{{el.age}}</strong></div>
                <div class="listTmp" :data-channel="'ticket'">{{el.ticketNum}}</div>
                <div class="cancel" v-if="el.age === '아가'"><span class="alertBox mdGray">취소완료</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb20">
        <button type="button" class="btn full mb20" @click="pointConversion" v-show="isList">쿠폰 전환</button>
      </div>
      <eagle-box
        v-show="!isList"
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :is-btn="btnTmp.isBtn"
        :btn-type="btnTmp.btnType"
        :btn-name="btnTmp.btnName"
      />
      <div class="mt30">
        <p class="lineTitle">포인트 안내사항</p>
        <p class="fz14 light colorSemiBlack">
          - 포인트 적립은 실결제 금액 기준으로 적립됩니다. (할인쿠폰으로 결제된 금액을 제외)</p>
        <p class="fz14 light colorSemiBlack">
          - 적립된 포인트는 '쿠폰 전환'하여 예매 시, 할인받으실 수 있습니다.</p>
        <p class="fz14 light colorSemiBlack">
          - 일부 상품(원정석 등)은 포인트 적립 대상에서 제외될 수 있습니다.</p>
        <p class="fz14 light colorSemiBlack">
          - 포인트 유효 기한은 매년 12월 31일 일괄 소멸 처리됩니다.</p>
      </div>
    </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import regExp from '@/common/regExp'
import EagleBox from '@/common/EagleBox.vue'
import imgPath from '@/library/imgPath'

export default {
  name: 'History',
  components: {EagleBox},
  mounted () {
    this.list = []
    this.isList = true
  },
  created () {
    this.thisYear = this.$store.state.thisYear
    this.lastYear = this.$store.state.lastYear
    this.fetchData()
  },
  computed: {
    name () {
      return this.$store.state.user.name
    }
  },
  data () {
    return {
      thisYear: '',
      lastYear: '',
      filter: '1', // 초기 필터 값 설정
      filterOptions: [
        {id: '1', name: '1개월'},
        {id: '3', name: '3개월'},
        {id: '6', name: '6개월'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      list: [],
      user_id: '',
      term: 1,
      btnTmp: {
        title: '',
        subTitle: '예매를 통해서 경남FC와 함께해주세요!',
        isBtn: true,
        btnName: '티켓예매',
        btnType: 'ticket'
      },
      isList: true
    }
  },
  methods: {
    async fetchData () {
      const date = {type: 'month', num: '1'}
      date.num = this.filter
      if (this.filter === 'thisYear') {
        date.type = 'year'
        date.num = this.thisYear
      }
      if (this.filter === 'lastYear') {
        date.type = 'year'
        date.num = this.lastYear
      }
      api.get(`/user/point?type=${date.type}&num=${date.num}`).then(res => {
        this.list = []
        const data = res.data
        const _tmp = data.map(row => {
          let point = row.point
          if (row.type === 'minus') {
            point *= -1
          } else {
            point = `+${point}`
          }
          row.icon = imgPath.setCouponIcon('point_basic')
          const type = row.type_check

          if (type.includes('signupPoint')) {
            row.icon = imgPath.setCouponIcon('point_basic')
          }
          if (type.includes('birthday_current_point')) {
            row.icon = imgPath.setCouponIcon('point_basic')
          }
          if (type.includes('ticket_coupon_exchange')) {
            row.icon = imgPath.setCouponIcon('coupon_ticket')
          }
          if (type === 'purchase_ticket_point') {
            row.icon = imgPath.setTeamLogo(row.away_team)
            row.content = `VS ${row.away_team_name}`
          }
          if (type.includes('purchase_md_point')) {
            row.icon = imgPath.setCouponIcon('point_md')
          }
          if (type.includes('survey_complete_point')) {
            row.icon = row.away_team ? imgPath.setTeamLogo(row.away_team) : imgPath.setCouponIcon('point_basic')
          }
          if (type.includes('ticket_point_exchange')) {
            row.icon = imgPath.setCouponIcon('point_ticket')
          }
          if (type.includes('md_exchange')) {
            row.icon = imgPath.setCouponIcon('point_md')
          }
          if (type === 'first_purchase_ticket_point') {
            row.icon = imgPath.setCouponIcon('point_basic')
          }

          const _row = {
            title: row.title,
            date: dayjs(row.cdate).format('YYYY.MM.DD'),
            point: point,
            icon: row.icon,
            state: row.type,
            setPoint: regExp.comma(point),
            content: row.content,
            margin_status: ''
          }

          return _row
        })
        this.list = _tmp
        this.isList = true
        if (this.list.length === 0) {
          this.btnTmp.title = `${this.name}님, 해당 기간에 포인트가 없습니다.`
          this.isList = false
        }
      })
      this.btnTmp.title = `${this.name}님, 해당 기간에 포인트가 없습니다.` // 추후 삭제요망 (더미용)
    },
    setFilter (duration) {
      this.filter = duration
      this.fetchData()
    },
    pointConversion () {
      this.$router.push({name: 'couponExchange'})
    }
  }
}
</script>
