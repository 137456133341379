import axios from 'axios'
import router from '@/router'

const instance = axios.create({
  baseURL: 'https://api.gmembers.kr',
  headers: {'content-type': 'application/json'},
  timeout: 30000,
  withCredentials: true,
  crossDomain: true,
  credentials: 'include'
})

instance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      config.headers['x-access-token'] = localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    localStorage.clear()
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (error && error?.code === 'ERR_NETWORK') {
      return router.push({name: 'errPage'})
    }
    if (error.response && error.response.status >= 500) {
      return router.push({name: 'errPage'})
    }
    const currentRoute = router.currentRoute // 쿠폰 등록 예외처리
    if (currentRoute.name !== 'couponExchange') {
      localStorage.clear()
    }
    console.log('ERROR', error)
    return Promise.reject(error)
  }
)
export default instance
