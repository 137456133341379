<template>
  <div class="containerWithArrow">
    <div>
      <div class="backSpace" @click="goBack"/>
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>설문조사</strong></p>
      </div>
    </div>
    <div class="mb30">
      <div class="surveyTileBox">
        <p class="mb10"><strong>{{ subject }}</strong></p>
        <p class="light colorLightGrey">
          {{ send_date }} {{send_date_time}} ~ {{ end_date }} {{end_date_time}}
        </p>
        <div class="mt10"><span class="alertBox BgRedForSurvey">진행중</span></div>
        <div class="light leftText mt20" v-html="description"></div>
      </div>
    </div>
    <div class="mb30">
      <p class="lineTitle">설문조사 참여</p>
      <div class="tileBox">

        <div class="mb30" v-bind:key="row.idx" v-for="(row, qIndex) in surveyItem">
          <!-- checkbox -->
          <div v-if="row.item_type === 'C'">
            <p>
              {{ row.itemIdx }}. {{ row.subject }}
              <span class="colorRed">({{ row.required_yn_text }})</span>
            </p>
            <ul>
              <li class="mt10" v-bind:key="aIndex" v-for="(oneItem, aIndex) in row.item">
                <div class="checkbox">
                  <input type="checkbox" v-bind:name="'checkbox_' + qIndex + '_' + aIndex"
                         v-bind:id="'checkbox_' + qIndex + '_' + aIndex"
                         v-bind:value="{answer_index: aIndex, item_idx: row.idx, answer_text: oneItem}"
                         v-model="answer[row.idx].answer"
                         @change="handleRadioChange(oneItem, row.idx)">
                  <label v-bind:for="'checkbox_' + qIndex + '_' + aIndex">{{ oneItem }}</label>
                  <input v-if="oneItem === '기타' && obj[row.idx]" type="text" class="input withCheckbox mt10" placeholder="입력하세요" v-bind:name="'checkbox_' + qIndex + '_' + aIndex" v-model="answer[row.idx].etcAnswer"
                         >
                </div>
              </li>
            </ul>
          </div>
          <!-- checkbox -->
          <div v-else-if="row.item_type === 'M'">
            <p>
              {{ row.itemIdx }}. {{ row.subject }}
              <span class="colorRed">({{ row.required_yn_text }})</span>
            </p>
            <ul>
              <li class="mt10" v-bind:key="aIndex" v-for="(oneItem, aIndex) in row.item">
                <div class="checkbox radio">
                  <input type="radio" v-bind:name="'checkbox_' + qIndex" v-bind:id="'checkbox_' + qIndex + '_' + aIndex"
                         v-bind:value="{answer_index: aIndex, item_idx: row.itemIdx, answer_text: oneItem}"
                         v-model="answer[row.idx].answer"
                         @change="handleRadioChange(oneItem, row.idx, 'M')"
                         ref="target">
                  <label v-bind:for="'checkbox_' + qIndex + '_' + aIndex">{{ oneItem }}</label>
                  <input v-if="oneItem === '기타' && obj[row.idx]" type="text" class="input withCheckbox mt10" placeholder="입력하세요"
                         v-bind:name="'checkbox_' + aIndex" v-model="answer[row.idx].etcAnswer"
                  >
                </div>
              </li>
            </ul>
          </div>
          <div v-else-if="row.item_type === 'T'">
            <p>
              {{ row.itemIdx }}. {{ row.subject }}
              <span class="colorRed">({{ row.required_yn_text }})</span>
            </p>
            <input type="text" class="input mt10" placeholder="입력하세요" v-model="answer[row.idx].answer" maxlength="300">
          </div>
          <div v-else-if="row.item_type === 'N'">
            <p>
              {{ row.itemIdx }}. {{ row.subject }}
              <span class="colorRed">({{ row.required_yn_text }})</span>
            </p>
            <ul>
              <li class="mt10" v-bind:key="aIndex" v-for="(oneItem, aIndex) in row._item">
                <div class="checkbox radio">
                  <input type="radio" v-bind:name="'checkbox_' + qIndex" v-bind:id="'checkbox_' + qIndex + '_' + aIndex"
                         v-bind:value="{answer_index: aIndex, item_idx: row.itemIdx, answer_text: row.item[aIndex]}"
                         v-model="answer[row.idx].answer">
                  <label v-bind:for="'checkbox_' + qIndex + '_' + aIndex">{{ oneItem }}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- agree -->
        <div>
          <p class="mb10">
            개인정보 수집 및 이용
            <span class="colorRed">(필수)</span>
          </p>
          <div class="textInput mb10">
            <div class="agreeBox fz14 light colorGrey" v-html="privacyAgreeText"></div>
          </div>
          <div class="agreeCheck mb20">
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_Y" value="Y" v-model="privacyAgree">
              <label for="agree_privacy_Y">동의</label>
            </span>
            <span class="checkbox">
              <input type="radio" name="agree_privacy" id="agree_privacy_N" value="N" v-model="privacyAgree">
              <label for="agree_privacy_N">미동의</label>
            </span>
          </div>
        </div>
        <!-- //agree -->
      </div>
      <button type="button" class="btn full mt30" @click="submit">제출하기</button>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import terms from '@/library/terms'
dayjs.extend(utc)

export default {
  name: 'Viewer',
  data () {
    return {
      subject: '',
      end_date: '',
      send_date: '',
      description: '',
      surveyItem: [],
      answer: {},
      privacyAgreeText: terms.addSurvey_terms,
      privacyAgree: '',
      target_idx: '',
      mobileno: '',
      isEtc: [],
      obj: {},
      some: false,
      send_date_time: '',
      end_date_time: ''
    }
  },
  computed: {
    isLogin () {
      return this.$store.getters['isLogin']
    }
  },
  beforeCreate () {
    if (this.$store.state.survey.goTarget === false) {
      alert('휴대폰 번호를 입력하세요.')
      return this.$router.go(-1)
    }
  },
  created () {
    this.survey_id = this.$route.params.id
    if (!this.isLogin) {
      if (this.$store.state.survey.target_idx === '') {
        alert('잘못된 접근입니다')
        this.$router.push({name: 'surveyHistory'})
      }
    }
    this.getSurvey()
    api.get('/user/detail')
      .then(res => {
        const mobileno = res.data.data.user.mobileno
        this.mobileno = mobileno.replace(/-/g, '')
        api.post(`/survey/${this.survey_id}/target_direct`, {target: this.mobileno})
          .then(res => {
            this.target_idx = res.data.target.idx
          })
      })
  },
  methods: {
    getSurvey () {
      api.get(`/survey/${this.survey_id}`).then(res => {
        this.surveyItem = []
        const surveyInfo = res.data.surveyInfo
        this.subject = surveyInfo.subject
        if (res.data.surveyInfo.end_date !== null) {
          const parsedTime = new Date(res.data.surveyInfo.end_date)
          const hours = parsedTime.getUTCHours().toString().padStart(2, '0')
          const minutes = parsedTime.getUTCMinutes().toString().padStart(2, '0')
          this.end_date_time = `${hours}:${minutes.toString().padStart(2, '0')}`
          this.end_date = dayjs.utc(res.data.surveyInfo.end_date).format('YYYY.MM.DD')
        }

        const parsedTime = new Date(res.data.surveyInfo.reserve_date)
        const hours = parsedTime.getUTCHours().toString().padStart(2, '0')
        const minutes = parsedTime.getUTCMinutes().toString().padStart(2, '0')
        this.send_date_time = `${hours}:${minutes.toString().padStart(2, '0')}`
        this.send_date = dayjs.utc(res.data.surveyInfo.reserve_date).format('YYYY.MM.DD')
        this.description = surveyInfo?.description.replace(/\n/g, '<br>')

        const surveyItem = res.data.surveyItem
        let idx = 1
        this.surveyItem = surveyItem.map(row => {
          this.answer[row.idx] = {
            idx: row.order_seq,
            required_yn: row.required_yn,
            item_type: row.item_type,
            etcAnswer: ''
          }
          if (row.item_type === 'C') {
            this.answer[row.idx].answer = []
          } else {
            this.answer[row.idx].answer = ''
          }
          row.itemIdx = idx++
          if (row.item_type !== 'T') {
            row.item = row.item_text.split('#')
          }

          if (row.required_yn === 'Y') {
            row.required_yn_text = '필수'
          } else {
            row.required_yn_text = '선택'
          }
          if (row.item_type === 'N') {
            let count = 6
            row.count = []
            row._item = row.item.map(item => {
              count--
              row.count.push(count)
              return `${item}(${count}점)`
            })
          }
          return row
        })
        this.surveyItem.map(el => {
          if (el.item && el.item.includes('기타')) {
            this.obj[el.idx] = false
          }
          return this.obj
        })
      })
    },
    submit () {
      // 필수 항목을 입력하지 않은 항목을 추적하는 배열
      const missingRequiredItems = []

      const _data = []
      for (const answer in this.answer) {
        const _template = {
          survey_item_idx: '',
          answer_index: '',
          answer_content: ''
        }
        const _target = this.answer[answer]
        if (_target.required_yn === 'Y') {
          if (_target.answer === '' && _target.item_type !== 'C') {
            missingRequiredItems.push(_target.idx)
          } else if (_target.answer.length === 0 && _target.item_type === 'C') {
            missingRequiredItems.push(_target.idx)
          }
        }

        if (_target.item_type === 'T') {
          _template.survey_item_idx = answer
          _template.answer_index = ''
          _template.answer_content = _target.answer
          _data.push(_template)
        }
        if (_target.item_type === 'C') {
          if (_target.answer.length === 0) {
            const _template = {
              survey_item_idx: answer,
              answer_index: '',
              answer_content: ''
            }
            _data.push(_template)
          } else {
            for (const oneAnswer of _target.answer) {
              const _template = {
                survey_item_idx: answer,
                answer_index: oneAnswer.answer_index
              }
              if (oneAnswer.answer_text === '기타') {
                _template.answer_content = _target.etcAnswer
              } else {
                _template.answer_content = oneAnswer.answer_text
              }
              _data.push(_template)
            }
          }
        }
        if (_target.item_type === 'N') {
          _template.survey_item_idx = answer
          _template.answer_index = _target.answer.length === 0 ? '' : _target.answer.answer_index
          _template.answer_content = _target.answer.length === 0 ? '' : _target.answer.answer_text
          _data.push(_template)
        }
        if (_target.item_type === 'M') {
          _template.survey_item_idx = answer
          _template.answer_index = _target.answer.length === 0 ? '' : _target.answer.answer_index
          if (_target.answer.answer_text === '기타') {
            _template.answer_content = _target.etcAnswer
          } else {
            _template.answer_content = _target.answer.length === 0 ? '' : _target.answer.answer_text
          }
          _data.push(_template)
        }
      }

      // 필수 항목을 입력하지 않은 경우 알럿 표시
      if (missingRequiredItems.length > 0) {
        const missingItemsMessage = missingRequiredItems.map(idx => `${idx}번`).join(', ')
        alert(`필수 항목 (${missingItemsMessage})을 입력해주세요.`)
        return
      }

      const answerArr = this.checkAndAlert(this.answer)

      if (answerArr.length > 0) {
        const _answerArr = answerArr.map(idx => `${idx}번`).join(', ')
        alert(`${_answerArr}의 기타항목을 입력해주세요.`)
        return
      }

      if (this.privacyAgree === '') {
        alert('개인정보 수집 및 이용 동의는 필수 선택 항목입니다.')
        return
      }

      if (this.privacyAgree === 'N') {
        alert('개인정보 수집 및 이용을 미동의할 경우 설문조사 참여에 제한됩니다.')
        return
      }
      const setData = this.removeEmptyAnswers(_data)
      // 제출
      api.post(`/survey/${this.target_idx}/send`, {
        target: this.mobileno,
        item: setData
      }).then(res => {
        alert('설문조사 제출이 완료되었습니다.\n참여해주셔서 감사합니다.')
        this.$router.push({name: 'surveyHistory'})
      })
    },
    goBack () {
      this.$router.go(-1) // 이전 페이지로 이동
    },
    checkAndAlert (data) {
      const idxArray = [] // "idx" 값을 저장할 배열
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          const item = data[key]
          if (
            item.required_yn === 'Y' && // required_yn이 'Y'인 경우
        (item.answer_text === '기타' || // answer_text가 '기타'인 경우
          (Array.isArray(item.answer) && item.answer.some(answer => answer.answer_text === '기타')) || // answer 배열에 '기타'가 있는 경우
          (typeof item.answer === 'object' && item.answer.answer_text === '기타')) && // answer 객체에 '기타'가 있는 경우
        item.etcAnswer === '' // etcAnswer가 빈 문자열인 경우
          ) {
            const idx = item.idx // 해당 항목의 idx 값 가져오기
            if (!idxArray.includes(idx)) {
              idxArray.push(idx) // 중복되지 않는 경우에만 "idx"를 배열에 추가
            }
          }
        }
      }
      return idxArray
    },
    handleRadioChange (selectedItem, idx, type) {
      if (selectedItem === '기타') {
        this.obj[idx] = !this.obj[idx]
        this.$set(this.obj, idx, this.obj[idx])
      }
      if (selectedItem === '기타' && type === 'M') {
        this.obj[idx] = true
        this.$set(this.obj, idx, this.obj[idx])
      }
      if (selectedItem !== '기타' && type === 'M') {
        this.obj[idx] = false
        this.$set(this.obj, idx, this.obj[idx])
      }
      this.obj = Object.assign({}, this.obj, this.obj)
    },
    removeEmptyAnswers (array) {
      return array.filter(item => item.answer_content !== '')
    }
  }
}
</script>
