<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>오류안내</strong></p>
    </div>

    <div class="mb20">
      <eagle-box
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :btn-name="btnTmp.btnName"
        :btnType="btnTmp.btnType"
        :is-btn="btnTmp.isBtn"
      />
    </div>

    <div class="mt50">
      <button type="button" class="btn full black" @click="goHome()">홈으로 돌아가기</button>
    </div>
  </div>
</template>

<script>
import EagleBox from '@/common/EagleBox.vue'

export default {
  name: 'errPage',
  components: {EagleBox},
  data () {
    return {
      btnTmp: {
        title: '일시적인 오류입니다.',
        subTitle: '잠시 후 다시 시도해 주세요.',
        isBtn: false,
        btnName: '',
        btnType: ''
      }
    }
  },
  methods: {
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
