<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>이벤트</strong></p>
    </div>
    <div class="btns mb20">
      <button
        v-for="(duration, idx) in filterOptions"
        :key="idx"
        type="button"
        class="btn componentTab"
        :class="{ btnGrey: filter !== duration.id }"
        @click="setFilter(duration.id)"
      >
        {{ duration.name }}
      </button>
    </div>
    <div v-if="isTargetDateTimeReached">
        <div class="eventBox mouseCursor"
             @click="view('F','')"
        >
          <div class="img">
            <img class="img" src="@/assets/img/fix_event_banner_2024.png" alt=""/>
          </div>
          <div class="titleBox mb10 mt15">
            <span class="title">마이 홈 데뷔 그라운드 체험</span>
          </div>
        </div>
      </div>
    <div>
      <div v-for="(item, i) in eventList" :key="i">
        <div class="eventBox mouseCursor"
             @click="view(item.set_status, item.idx)"
        >
          <div class="img">
            <img class="img"
                 :class="item.set_status!=='Y' ? 'gradient' : ''"
                 :src="item.thumnail_pic" alt=""/>
          </div>
          <div class="titleBox mb10 mt15">
            <span class="title">{{ item.event_name }}</span>
            <span
              class="alertBox event"
              :class="item.btnState"
            >{{item.setEvent}}</span>
          </div>
          <p class="subTitle">{{ item.guide_msg }}</p>
          <p class="time">{{ item.set_start_apply }} ~ {{item.set_end_apply}}</p>
        </div>
      </div>
      <!--      <eagle-box-->
      <!--        v-show="!isList"-->
      <!--        :title="btnTmp.title"-->
      <!--        :sub-title="btnTmp.subTitle"-->
      <!--        :is-btn="btnTmp.isBtn"-->
      <!--      />-->
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

// import EagleBox from '@/common/EagleBox.vue'

export default {
  name: 'History',
  // components: {EagleBox},
  data () {
    return {
      thisYear: '',
      lastYear: '',
      filter: '', // 초기 필터 값 설정
      filterOptions: [
        {id: '', name: '진행중'},
        {id: 'end', name: '마감'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      term: 1,
      eventList: [],
      btnTmp: {
        title: '해당 기간에 진행된 이벤트가 없습니다.',
        subTitle: '기간 변경하시거나, 이벤트를 기다려주세요!',
        isBtn: false
      },
      isList: true
    }
  },
  created () {
    this.thisYear = this.$store.state.thisYear
    this.lastYear = this.$store.state.lastYear
    this.fetchData()
  },
  computed: {
    isTargetDateTimeReached () {
      const targetDateTime = dayjs('2024-05-20T00:00:00')
      const currentTime = dayjs()
      return currentTime.isAfter(targetDateTime)
    }
  },
  methods: {
    setFilter (duration) {
      this.filter = duration
      this.fetchData()
    },
    fetchData () {
      const date = {type: '', num: ''}
      date.type = this.filter
      if (this.filter === 'thisYear') {
        date.type = 'year'
        date.num = this.thisYear
      }
      if (this.filter === 'lastYear') {
        date.type = 'year'
        date.num = this.lastYear
      }
      api.get(`/event?type=${date.type}&year=${date.num}`).then(res => {
        const today = dayjs()
        res.data.map(row => {
          row.set_start_apply = dayjs(row.start_apply).format('YYYY.MM.DD HH:mm')
          row.set_end_apply = dayjs(row.end_apply).format('YYYY.MM.DD HH:mm')
          if (date.type === '') {
            row.set_status = 'Y'
            row.setEvent = '진행중'
            row.btnState = 'BgRed'
            if (dayjs(row.start_apply).isSameOrAfter(today)) {
              row.set_status = 'Y'
              row.setEvent = '진행예정'
              row.btnState = 'red'
            }
            if (!dayjs(row.end_apply).isSameOrAfter(today)) {
              row.set_status = null
              row.setEvent = '마감'
              row.btnState = 'mdGray'
            }
          }
          if (date.type === 'end') {
            row.set_status = null
            row.setEvent = '마감'
            row.btnState = 'mdGray'
          }
          if (date.type === 'year' && date.num === this.thisYear) {
            row.set_status = 'Y'
            row.setEvent = '진행중'
            row.btnState = 'BgRed'
            if (dayjs(row.start_apply).isSameOrAfter(today)) {
              row.set_status = 'Y'
              row.setEvent = '진행예정'
              row.btnState = 'red'
            }
            if (!dayjs(row.end_apply).isSameOrAfter(today)) {
              row.set_status = null
              row.setEvent = '마감'
              row.btnState = 'mdGray'
            }
          }
          if (date.type === 'year' && date.num === this.lastYear) {
            row.set_status = null
            row.setEvent = '마감'
            row.btnState = 'mdGray'
          }
          return row
        })

        this.eventList = res.data
        if (res.data.length !== 0) {
          this.eventList = res.data.sort((a, b) => new Date(b.set_start_apply) - new Date(a.set_start_apply))
        }
        this.isList = this.eventList.length !== 0
      })
    },
    view (state, idx) {
      if (state === 'Y') {
        this.$router.push({ name: 'eventView', params: { id: idx } })
      } else if (state === 'F') {
        this.$router.push({ name: 'fixedEventView'})
      } else {
        alert('종료된 이벤트입니다.')
      }
    }
  }
}
</script>
