<template>
<div>
  <div class="eagleContainer">
  <div class="eagle">
 <img class="eagleImg" src="@/assets/img/ico_eagle.png"/>
  </div>
<div class="box">
  <div class="tmpBox">
    <p class="title">{{title}}</p>
    <p class="subTitle" v-html="subTitle"></p>
  </div>
  <button v-if="isBtn" class="btn" :data-channel="btnType" @click="fetch">{{btnName}}</button>
</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'eagleBox',
  props: [
    'title',
    'subTitle',
    'btn',
    'btnType',
    'btnName',
    'isBtn'
  ],
  data () {
    return {
    }
  },
  methods: {
    fetch () {
      if (this.btnType === 'refrash') {
        location.reload()
      }
      if (this.btnType === 'ticket') {
        this.$router.push({name: 'ticket'})
      }
      if (this.btnType === 'coupon') {
        this.$router.push({ name: 'couponExchange' })
      }
      if (this.btnType === 'back') {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
