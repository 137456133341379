<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>쿠폰 내역</strong></p>
    </div>
    <div class="btns mb20">
      <button
        v-for="(duration, idx) in filterOptions"
        :key="idx"
        type="button"
        class="btn componentTab"
        :class="{ btnGrey: filter !== duration.id }"
        @click="setFilter(duration.id)"
      >
        {{ duration.name }}
      </button>
    </div>
    <div class="historyList mb30">
      <div class="mb20">
        <div class="detailBox">
          <div class="box" v-for="(item, i) in list" :key="i">
            <div class="mainBox">
              <div class="titleBox">
                <div class="logo mr10">
                  <img class="img" :src="item.icon"></div>
                <div class="content leftText">
                  <p class="fz14"><strong>{{item.title}}</strong></p>
                  <p class="fw400 mt5 fz14 ">{{item.content}}</p>
                  <p class="fw400 mt5 colorLightGrey fz14">{{item.date}}</p>
                  <div class="alert">
                    <button class="couponNum mouseCursor" @click="copy(item.id)">{{item.id}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn full mb20" @click="pointConversion" v-show="isList">쿠폰 전환</button>
      </div>
    </div>
    <eagle-box
      v-show="!isList"
      :title="btnTmp.title"
      :sub-title="btnTmp.subTitle"
      :is-btn="btnTmp.isBtn"
      :btn-type="btnTmp.btnType"
      :btn-name="btnTmp.btnName"
    />
    <div class="mt30">
      <p class="lineTitle">쿠폰 사용 방법</p>
      <p class="mb10 fz14 light colorSemiBlack" v-html="couponText">
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import EagleBox from '@/common/EagleBox.vue'
import imgPath from '@/library/imgPath'

export default {
  name: 'History',
  components: {EagleBox},
  mounted () {
    this.list = []
  },
  computed: {
    name () {
      return this.$store.state.user.name
    }
  },
  data () {
    return {
      thisYear: '',
      lastYear: '',
      filter: '1', // 초기 필터 값 설정
      filterOptions: [
        {id: '1', name: '1개월'},
        {id: '3', name: '3개월'},
        {id: '6', name: '6개월'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      list: [],
      btnTmp: {
        title: '',
        subTitle: '쿠폰 전환을 통해서 경남FC와 함께해주세요!',
        isBtn: true,
        btnName: '쿠폰 전환',
        btnType: 'coupon'
      },
      isList: true,
      term: 1,
      user_id: '',
      couponText: `
       - 쿠폰 번호를 선택하면 자동으로 쿠폰이 복사됩니다.<br>
        - 복사된 쿠폰은 아래와 같이 사용 가능합니다.<br>
        <티켓 예매> → <관람 경기 선택> → <등급/좌석 선택> → <할인권종 선택> → <쿠폰 번호 입력  or 붙여넣기> → <등록 후 적용하여 사용><br>
        - 티켓 1매당 1개 쿠폰만 사용 가능하며, 어린이 권종은 사용 불가합니다.<br>
        - 발급된 쿠폰은 현장 매표소에서 사용 불가합니다.<br>
        - 쿠폰 유효 기한은 매년 12월 31일 일괄 소멸 처리되며, 예외로 2023시즌에 발급된 쿠폰은 2024시즌까지 사용 가능합니다.<br>
      `
    }
  },
  created () {
    this.thisYear = this.$store.state.thisYear
    this.lastYear = this.$store.state.lastYear
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const date = {type: 'month', num: '1'}
      date.num = this.filter
      if (this.filter === 'thisYear') {
        date.type = 'year'
        date.num = this.thisYear
      }
      if (this.filter === 'lastYear') {
        date.type = 'year'
        date.num = this.lastYear
      }
      api.get(`/user/coupon?type=${date.type}&num=${date.num}`).then(res => {
        this.list = []
        const data = res.data
        const _tmp = data.map(row => {
          row.icon = imgPath.setCouponIcon('point_basic')
          const type = row.coupon_type
          if (type.includes('ticket_coupon')) {
            row.icon = imgPath.setCouponIcon('coupon_ticket')
          }
          if (type.includes('md_coupon')) {
            row.icon = imgPath.setCouponIcon('coupon_md')
          }
          if (type.includes('event_april_picnic')) {
            row.icon = imgPath.setCouponIcon('point_basic')
          }
          if (type.includes('event_april_economy')) {
            row.icon = imgPath.setCouponIcon('point_basic')
          }
          const _row = {
            title: row.title,
            date: dayjs(row.send_date).format('YYYY.MM.DD'),
            content: row.content,
            id: row.coupon_num,
            margin_status: '',
            icon: row.icon
          }
          return _row
        })
        this.list = _tmp
        if (this.list.length === 0) {
          this.btnTmp.title = `${this.name}님, 해당 기간에 쿠폰이 없습니다.`
          this.isList = false
        } else {
          this.isList = true
        }
      })
    },
    copy (cNum) {
      navigator.clipboard.writeText(cNum).then(() => {
        alert('쿠폰 번호가 복사되었습니다.')
      }).catch(err => {
        alert('복사에 실패했습니다. 다시 시도해주세요.')
        console.error('복사 실패:', err)
      })
    },
    setFilter (duration) {
      this.filter = duration
      this.fetchData()
    },
    pointConversion () {
      this.$router.push({name: 'couponExchange'})
    }
  }
}
</script>
