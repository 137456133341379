<template>
  <div class="containerWithArrow">
    <div>
      <div class="backSpace" @click="goBack"/>
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>설문조사</strong></p>
      </div>
    </div>
    <div class="mb30">
      <div class="surveyTileBox">
        <p class="mb10"><strong>{{ subject }}</strong></p>
        <p class="light colorLightGrey">
          {{ send_date }} {{send_date_time}} ~ {{ end_date }} {{end_date_time}}
        </p>
        <div class="mt10"><span class="alertBox BgRedForSurvey">진행중</span></div>
        <div class="light leftText mt20" v-html="description"></div>
      </div>
    </div>
    <div>
      <p class="lineTitle">설문조사 참여</p>
      <div class="tileBox">
        <div class="formGroup">
          <div class="label">휴대폰번호</div>
          <div class="value">
            <input type="text" class="input mb10" placeholder="휴대폰 번호를 입력하세요" v-model="target_number" ref="target_number">
            <button type="button" class="btn compact" @click="confirm" ref="btnTargetCheck">대상자 확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'TargetDirect',
  data () {
    return {
      survey_id: '',
      subject: '',
      idx: '',
      end_date: '',
      send_date: '',
      reserve_date: '',
      description: '',
      target_number: '',
      survey_status: true,
      send_date_time: '',
      end_date_time: ''
    }
  },
  created () {
    this.survey_id = this.$route.params.id
    this.fetchData()
    this.$store.state.survey.goTarget = false
  },
  methods: {
    fetchData () {
      api.get(`/survey/${this.survey_id}/info`).then(res => {
        this.subject = res.data.subject
        if (res.data.end_date !== null) {
          const parsedTime = new Date(res.data.end_date)
          const hours = parsedTime.getUTCHours().toString().padStart(2, '0')
          const minutes = parsedTime.getUTCMinutes().toString().padStart(2, '0')
          this.end_date_time = `${hours}:${minutes.toString().padStart(2, '0')}`
          this.end_date = dayjs(res.data.end_date).format('YYYY.MM.DD HH:mm')
          if (dayjs.utc(res.data.end_date).isSameOrBefore(dayjs())) {
            this.survey_status = false
            this.$refs.target_number.disabled = true
            this.$refs.btnTargetCheck.classList = 'btn compact disabled'
            alert('설문조사가 종료되었습니다.')
          }
        }
        const parsedTime = new Date(res.data.reserve_date)
        const hours = parsedTime.getUTCHours().toString().padStart(2, '0')
        const minutes = parsedTime.getUTCMinutes().toString().padStart(2, '0')
        this.send_date_time = `${hours}:${minutes.toString().padStart(2, '0')}`
        this.send_date = dayjs.utc(res.data.send_date).format('YYYY.MM.DD')
        this.description = res.data.description?.replace(/\n/g, '<br>')
      })
    },
    confirm () {
      if (this.survey_status === false) {
        return
      }
      api.post(`/survey/${this.survey_id}/target_direct`, {
        target: this.target_number
      }).then(res => {
        if (res.data.err === true) {
          if (res.data.state === 'C' || res.data.status === 'date') {
            alert(`${res.data.message}`)
          } else {
            alert('설문조사 대상자가 아닙니다')
          }
        } else {
          this.$store.state.survey.target_idx = res.data.target.idx
          this.$store.state.survey.goTarget = true
          localStorage.setItem('mobile', this.target_number)
          alert('설문조사 참여자 대상입니다. 설문조사 페이지로 이동합니다.')
          this.$router.push({ name: 'surveyViewDirect', params: { id: this.survey_id} })
        }
      })
        .catch(err => {
          if (err.response.data.err === true) {
            return alert(`${err.response.data.message}`)
          }
        })
    },
    goBack () {
      this.$router.go(-1) // 이전 페이지로 이동
    }
  }
}
</script>
