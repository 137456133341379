var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer"}},[_vm._m(0),_c('ul',{staticClass:"bizInfoBox"},[_c('p',{staticClass:"name"},[_vm._v("주식회사 경남도민프로축구단")]),_vm._m(1),_c('li',[_c('div',{staticClass:"dt"},[_vm._v("주소")]),_c('div',{staticClass:"dd"},[_vm._v("경상남도 창원시 성산구 비음로 97, 1층("),_c('span',{on:{"click":_vm.test}},[_vm._v("사파")]),_vm._v("정동)")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('p',{staticClass:"copy"},[_vm._v("Copyright ⓒ GYEONGNAM FC All Rights Reserved.")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"links"},[_c('a',{staticClass:"link privacy colorRed",attrs:{"href":"https://www.gyeongnamfc.com/member/privacy.php","target":"_blank"}},[_vm._v("개인정보처리방침")]),_c('span',{staticClass:"linkSpan"},[_vm._v("  |  ")]),_c('a',{staticClass:"link service",attrs:{"href":"https://www.gyeongnamfc.com/member/terms.php","target":"_blank"}},[_vm._v("이용약관")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("대표이사")]),_c('div',{staticClass:"dd"},[_vm._v("지현철")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("사업자등록번호")]),_c('div',{staticClass:"dd"},[_vm._v("609-81-69085"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("통신판매신고")]),_c('div',{staticClass:"dd"},[_vm._v("2005-경남창원-0170"),_c('a',{staticClass:"linkStyle",attrs:{"href":"https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6098169085","target":"_blank"}},[_vm._v("사업자정보확인")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("개인정보처리책임자")]),_c('div',{staticClass:"dd"},[_vm._v("최한얼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("전화번호")]),_c('div',{staticClass:"dd"},[_c('a',{attrs:{"href":"tel:0552832020"}}),_vm._v("055-283-2020")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("팩스")]),_c('div',{staticClass:"dd"},[_vm._v("055-283-2992")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("이메일")]),_c('div',{staticClass:"dd"},[_c('a',{attrs:{"href":"mailto:gnfc_official@naver.com"}}),_vm._v("gnfc_official@naver.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"dt"},[_vm._v("호스팅사업자")]),_c('div',{staticClass:"dd"},[_vm._v("AWS(Amazon Web Service), 더스포츠커뮤니케이션")])])
}]

export { render, staticRenderFns }