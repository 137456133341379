<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>로그인</strong></p>
    </div>
    <div class="mb20">
      <p class="lineTitle">G멤버스 로그인</p>
      <div class="tileBox">
        <div class="formGroup">
          <div class="label">아이디</div>
          <div class="value">
            <input type="text" class="input" placeholder="아이디를 입력하세요" v-model="id">
          </div>
        </div>
        <div class="formGroup">
          <div class="label">비밀번호</div>
          <div class="value">
            <input type="password" class="input" placeholder="비밀번호를 입력하세요" v-model="pw">
          </div>
        </div>
      </div>
    </div>
    <div class="mb20">
      <button type="button" class="btn full red" @click="login">로그인</button>
    </div>
    <div class="mb20">
      <a href="https://www.gyeongnamfc.com/member/signup_1.php" target="_blank" class="btn full black">회원가입</a>
    </div>
    <div class="mb20">
      <a href="https://www.gyeongnamfc.com/member/id_pw_search.php" target="_blank" class="btn full lightGrey">아이디 찾기</a>
    </div>
    <div >
      <a href="https://www.gyeongnamfc.com/member/id_pw_search.php" target="_blank" class="btn full lightGrey">비밀번호 찾기</a>
    </div>
  </div>
</template>

<script>
import http from '@/api'
import regExp from '@/common/regExp'
import dayjs from 'dayjs'

export default {
  name: 'Login',
  data () {
    return {
      token: '',
      id: '',
      pw: '',
      redirectUrl: '/'
    }
  },
  created () {
    if (this.$route.query.redirect) {
      this.redirectUrl = this.$route.query.redirect
    }
  },
  methods: {
    login: function () {
      if (!this.id) {
        return alert('아이디를 입력하세요.')
      }
      if (!this.pw) {
        return alert('비밀번호를 입력하세요.')
      }
      const data = {
        user_id: this.id,
        user_pw: this.pw
      }
      http.post('/account/login', data).then(res => {
        if (res.data.success === true) {
          this.token = res.data.data.token
          this.$store.commit('login', true)
          localStorage.setItem('token', this.token)
          if (res.data.change_pw === true) {
            alert('마지막 비밀번호를 변경한지 180일이 지났습니다.\n비밀번호를 변경해주세요.')
            return this.$router.push({name: 'myPage'})
          }
          alert('로그인이 완료되었습니다.')
          this.$router.push(this.redirectUrl)
        }
      }).catch(err => {
        alert(`${err.response.data.message}`)
        if (err.response.data.status === 'migration') {
          return window.open('https://www.gyeongnamfc.com/member/certification.php', '_blank')
        }
        if (document.addEventListener) {
          window.addEventListener('pageshow', function (event) {
            if (event.persisted || performance.getEntriesByType('navigation')[0].type === 'back_forward') {
              location.reload()
            }
          },
          false)
        }
      })
    }
  }
}
</script>
