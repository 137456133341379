import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '@/views/NotFound'
import Main from '@/views/Main'
import SurveyHistory from '@/components/Survey/History'
import SurveyTarget from '@/components/Survey/Target'
import SurveyTargetDirect from '@/components/Survey/TargetDirect'
import SurveyView from '@/components/Survey/Viewer'
import SurveyViewDirect from '@/components/Survey/ViewerDirect'
import PointHistory from '@/components/Point/History'
import CouponExchange from '@/components/Coupon/CouponExchange.vue'
import CouponHistory from '@/components/Coupon/History'
import EventHistory from '@/components/Event/History'
import EventView from '@/components/Event/Viewer'
import FixedEventView from '@/components/Event/FixedEventView'
import Login from '@/components/Member/Login'
import Logout from '@/components/Member/Logout'
import Myinfo from '@/components/Member/Myinfo'
// import Auth from '@/components/Member/Auth'
// import Agree from '@/components/Member/Agree'
import Membership from '@/components/Membership/History'
import AddMembership from '@/components/Membership/AddMembership'
import BuyMembership from '@/components/Membership/BuyMembership.vue'
import ReservationList from '@/components/reservationList/History'
import Attendance from '@/components/Attendance/Attendance.vue'
import Ticket from '@/components/Ticket/Ticket.vue'
import Stadium from '@/components/Stadium/Stadium.vue'
import ga from '@/library/ga4'
import ErrPage from '@/common/ErrPage.vue'
import AppLogin from '@/components/Member/AppLogin.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: ErrPage
    },
    {
      path: '/',
      name: 'main',
      component: Main,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/survey/history',
      name: 'surveyHistory',
      component: SurveyHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/survey/target/:id',
      name: 'surveyTarget',
      component: SurveyTarget,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/survey/target/direct/:id',
      name: 'surveyTargetDirect',
      component: SurveyTargetDirect,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/survey/view/:id',
      name: 'surveyView',
      component: SurveyView,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/survey/view/direct/:id',
      name: 'surveyViewDirect',
      component: SurveyViewDirect,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/point/history',
      name: 'pointHistory',
      component: PointHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/coupon/CouponExchange',
      name: 'couponExchange',
      component: CouponExchange,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/coupon/history',
      name: 'couponHistory',
      component: CouponHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/event/history',
      name: 'eventHistory',
      component: EventHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/event/view/:id',
      name: 'eventView',
      component: EventView,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/event/fan_escort_kids',
      name: 'fixedEventView',
      component: FixedEventView,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/join',
      name: 'join',
      beforeEnter: (to, from, next) => {
        window.open('https://www.gyeongnamfc.com/member/signup_1.php', '_blank')
        next('/login')
      }
    },
    {
      path: '/myinfo',
      name: 'myinfo',
      component: Myinfo,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reservationList',
      name: 'reservationList',
      component: ReservationList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/membership',
      name: 'membership',
      component: Membership,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/addMembership',
      name: 'addMembership',
      component: AddMembership,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/buyMembership',
      name: 'buyMembership',
      component: BuyMembership,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/ticket',
      name: 'ticket',
      component: Ticket,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/attendance',
      name: 'attendance',
      component: Attendance,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/stadium',
      name: 'stadium',
      component: Stadium,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/appLogin',
      name: 'appLogin',
      component: AppLogin,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/err',
      name: 'errPage',
      component: ErrPage,
      meta: {
        requiresAuth: false
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.meta.requiresAuth && !token) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.path.split('/')[1] === 'ticket') {
    ga.event('TicketView')
  }
})

export default router
