<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>시즌권 등록</strong></p>
    </div>
    <div class="mb20" v-if="!isApply">
      <p class="lineTitle">시즌권 등록</p>
      <div class="tileBox">
        <div class="formGroup">
          <div class="label">이름</div>
          <div class="value">
            <input type="text" class="input" disabled :placeholder="name">
          </div>
        </div>

        <div class="formGroup">
          <div class="label">연락처</div>
          <div class="value">
            <input type="text" class="input" disabled :placeholder="mobile">
          </div>
        </div>

        <div class="formGroup">
          <div class="label">시즌권 번호</div>
          <div class="value">
            <input type="text" placeholder="시즌권 번호를 입력해주세요."
                   class="input"
                   style="text-transform: uppercase"
                   v-model="membership_no"
                   @keyup="chkCharCode($event)"
            >
          </div>
        </div>

            <p class="mt15 mb10 ml15 mr10 fw500 colorBlack">개인정보 수집 및 이용<span class="colorRed">(필수)</span></p>
            <div class="textInput mb10">
              <div class="agreeBox fz14 light colorGrey" v-html="addMembership_terms"></div>
            </div>
            <div class="agreeCheck mb20">
            <span class="checkbox">
              <input type="radio" name="agree_sms" id="agree_sms_Y" value="Y" v-model="agree">
              <label for="agree_sms_Y">동의</label>
            </span>
              <span class="checkbox">
              <input type="radio" name="agree_sms" id="agree_sms_N" value="N" v-model="agree">
              <label for="agree_sms_N">미동의</label>
            </span>
            </div>
          </div>

    </div>
    <div class="mb20" v-else>
      <eagle-box
        v-if="isApply"
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :is-btn="btnTmp.isBtn"
      />
    </div>
    <div class="mb20">
      <button type="button" class="btn full" @click="apply" v-if="!isApply">등록하기</button>
      <router-link class="btn full" :to="{name: 'membership'}" v-else>조회하기</router-link>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from '@/api/index'
import terms from '@/library/terms'
import EagleBox from '@/common/EagleBox.vue'
import 'dayjs/locale/ko'
import imgPath from '@/library/imgPath'
dayjs.locale('ko')

export default {
  name: 'AddMembership',
  components: {EagleBox},
  created () {
    this.year = dayjs().format('YYYY')
    api.get('/user/detail')
      .then(res => {
        this.name = res.data.data.user.name
        this.mobile = res.data.data.user.mobileno
      })
  },
  data () {
    return {
      year: '',
      agree: '',
      name: '',
      mobile: '',
      membership_no: '',
      isMembership: false,
      addMembership_terms: terms.addMembership_terms,
      btnTmp: {
        title: '',
        subTitle: '',
        isBtn: false,
        btnName: '',
        btnType: ''
      },
      isApply: false
    }
  },
  methods: {
    apply () {
      const data = {number: this.membership_no}
      if (data.number === '') {
        return alert('시즌권 번호를 입력하세요.')
      }
      if (this.agree === '') {
        return alert('개인정보 수집 및 이용 항목을 선택해주세요.')
      }
      if (this.agree === 'N') {
        return alert('개인정보 수집 및 이용을 미동의할 경우\n시즌권 등록이 제한됩니다.')
      }
      api.post('/ticket/season', data)
        .then(res => {
          if (res.data.err === false) {
            alert('시즌권 등록이 완료되었습니다.')
            this.isApply = true
            this.btnTmp.title = `${this.name}님, 시즌권이 정상적으로 등록되었습니다.`
            this.btnTmp.subTitle = '지금부터 등록하신 시즌권 조회 하실 수 있습니다.'
          }
        })
        .catch(err => {
          if (err.response.data.err === true) {
            return alert(`${err.response.data.message}`)
          }
        })
    },
    chkCharCode (event) {
      const regExp = /[^0-9a-zA-Z]/g
      const ele = event.target
      if (regExp.test(ele.value)) {
        ele.value = ele.value.replace(regExp, '')
      }
    }
  }
}
</script>
