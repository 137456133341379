<template>
  <div class="container">
    <div class="historyList mb30">
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>출석체크</strong></p>
      </div>
      <div class="detailBox">
        <div class="box" v-for="(item, i) in list" :key="i">
          <div class="mainBox">
            <div class="titleBox">
              <div class="mr5 logo">
                <img class="attendance" :src="item.set_logo"></div>
              <div class="content leftText">
                <p><strong>vs {{item.set_match_info}}</strong></p>
                <p class="fw400">{{item.set_game_date}}</p>
              </div>
              <div class="alert attendance">
                <span class="alertBox" :class="item.attend ? 'red' : 'mdGray'">{{item.attend ? '출석완료' : '결석'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <eagle-box
        v-show="!isList"
        :title="btnTmp.title"
        :sub-title="btnTmp.subTitle"
        :is-btn="btnTmp.isBtn"
        :btn-type="btnTmp.btnType"
        :btn-name="btnTmp.btnName"
      />
    </div>
  </div>
</template>
<script>
import EagleBox from '@/common/EagleBox.vue'
import http from '@/api/index'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import imgPath from '@/library/imgPath'
dayjs.locale('ko')

export default {
  name: 'Attendance',
  components: {EagleBox},
  created () {
    this.fetchData()
  },
  computed: {
    name () {
      return this.$store.state.user.name
    }
  },
  data () {
    return {
      type: '',
      list: [],
      term: 1,
      btnTmp: {
        title: '',
        subTitle: '예매를 통해서 경남FC와 함께해주세요!',
        isBtn: true,
        btnName: '티켓예매',
        btnType: 'ticket'
      },
      isList: true
    }
  },
  methods: {
    fetchData () {
      http.get('/ticket/attend').then(res => {
        const list = res.data.list
        const currentTime = dayjs()
        const filteredGames = list.filter(game => { // 현재시간 기준 이전경기만 보여줌
          const gameDateTime = dayjs(`${game.game_date} ${game.game_time}`).subtract(3, 'hour')
          return gameDateTime.isBefore(currentTime)
        })
        const regex = /vs (.*?) \(/
        if (filteredGames.length > 0) {
          filteredGames.map(el => {
            el.set_match_info = el.away_team_name
            if (el.away_team === 'F29') {
              el.away_team = 'K301'
            }
            el.set_logo = imgPath.setTeamLogo(el.away_team)
            el.set_game_date = `${dayjs(el.game_date).format('YYYY.MM.DD')} (${dayjs(el.game_date).format('ddd')}) ${el.game_time.substring(0, 5)}`
            return el
          })
          // 날짜 오름차순
          filteredGames.sort((a, b) => {
            const dateA = new Date(a.game_date)
            const dateB = new Date(b.game_date)
            if (dateA > dateB) return -1
            if (dateA < dateB) return 1
            return 0
          })
          this.list = filteredGames
          this.isList = true
        } else {
          this.btnTmp.title = `${this.name}님, 아직 출석한 기록이 없습니다!`
        }
      })
    }
  }
}
</script>
