var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"btns mb20"},_vm._l((_vm.filterOptions),function(duration,idx){return _c('button',{key:idx,staticClass:"btn componentTab",class:{ btnGrey: _vm.filter !== duration.id },attrs:{"type":"button"},on:{"click":function($event){return _vm.setFilter(duration.id)}}},[_vm._v(" "+_vm._s(duration.name)+" ")])}),0),_c('div',{staticClass:"historyList mb30"},[_c('div',{staticClass:"detailBox"},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"box"},[_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"titleBox"},[_c('div',{staticClass:"mr5 logo"},[_c('img',{staticClass:"attendance",attrs:{"src":item.icon !== ''
                   ? item.icon
                   : require('@/assets/img/K06_dummy.png')}})]),_c('div',{staticClass:"content leftText"},[_c('p',[_c('strong',[_vm._v(_vm._s(item.title))])]),_c('p',{staticClass:"fw400 mt5"},[_vm._v(_vm._s(item.content))]),_c('p',{staticClass:"colorLightGrey mt5"},[_vm._v(_vm._s(item.date))])]),_c('div',{staticClass:"alert attendance"},[_c('span',{staticClass:"point",class:item.state!=='minus'
                    ? 'colorRed'
                    : 'colorGrey'},[_vm._v(" "+_vm._s(item.setPoint))])])])]),(item.open)?_c('div',{staticClass:"subBox"},_vm._l((item.detail),function(el,idx){return _c('div',{key:idx,staticClass:"listBox"},[_c('div',{staticClass:"listTmp",attrs:{"data-channel":'refrash'}},[_c('strong',[_vm._v(_vm._s(el.seat))])]),_c('div',{staticClass:"listTmp",attrs:{"data-channel":'age'}},[_c('strong',[_vm._v(_vm._s(el.age))])]),_c('div',{staticClass:"listTmp",attrs:{"data-channel":'ticket'}},[_vm._v(_vm._s(el.ticketNum))]),(el.age === '아가')?_c('div',{staticClass:"cancel"},[_c('span',{staticClass:"alertBox mdGray"},[_vm._v("취소완료")])]):_vm._e()])}),0):_vm._e()])}),0)]),_c('div',{staticClass:"mb20"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isList),expression:"isList"}],staticClass:"btn full mb20",attrs:{"type":"button"},on:{"click":_vm.pointConversion}},[_vm._v("쿠폰 전환")])]),_c('eagle-box',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isList),expression:"!isList"}],attrs:{"title":_vm.btnTmp.title,"sub-title":_vm.btnTmp.subTitle,"is-btn":_vm.btnTmp.isBtn,"btn-type":_vm.btnTmp.btnType,"btn-name":_vm.btnTmp.btnName}}),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoTitle colorBlack"},[_c('p',{staticClass:"main"},[_vm._v("G멤버스 "),_c('strong',[_vm._v("포인트 조회")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt30"},[_c('p',{staticClass:"lineTitle"},[_vm._v("포인트 안내사항")]),_c('p',{staticClass:"fz14 light colorSemiBlack"},[_vm._v(" - 포인트 적립은 실결제 금액 기준으로 적립됩니다. (할인쿠폰으로 결제된 금액을 제외)")]),_c('p',{staticClass:"fz14 light colorSemiBlack"},[_vm._v(" - 적립된 포인트는 '쿠폰 전환'하여 예매 시, 할인받으실 수 있습니다.")]),_c('p',{staticClass:"fz14 light colorSemiBlack"},[_vm._v(" - 일부 상품(원정석 등)은 포인트 적립 대상에서 제외될 수 있습니다.")]),_c('p',{staticClass:"fz14 light colorSemiBlack"},[_vm._v(" - 포인트 유효 기한은 매년 12월 31일 일괄 소멸 처리됩니다.")])])
}]

export { render, staticRenderFns }