const survey = {
  namespaced: true,
  state: {
    target_idx: '',
    survey_idx: '',
    goSurvet: false,
    goTarget: false
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
}

export default survey
