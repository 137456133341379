<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>시즌권</strong></p>
    </div>
    <p class="lineTitle">나의 시즌권</p>
    <carousel
      :perPage="1"
      :paginationPadding="2"
      :loop="true"
      :paginationActiveColor="'#FFFFFF'"
      :pagination-color="'rgba(81, 82, 87, 0.2)'"
      ref="logoutMain"
    >
      <slide
        v-for="(el, index) in ticketList"
        :key="index"
        :data-index="index"
        class="slide">
        <div class="sliderInner justify_content_center">
          <div class="seasonTicket">
            <div>
              <div class="contentBox">
                <div class="mb10 fw800">2024 경남FC 시즌권</div>
                <div>{{el.product}}</div>
                <div>{{el.ticket_seat_1}} {{el.ticket_seat_2}} {{el.ticket_seat_3}}</div>
              </div>
              <div class="barcodeBox" style="text-align: center">
                <barcode :value="el.number"
                         :text-margin="5"
                         :width="1.5"
                         font-size="14"/>
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <div class="mb20 mt15">
      <p class="lineTitle" v-if="ticketList.length !== 0">시즌권 선택 및 조회 결과</p>
      <div v-if="ticketList.length !== 0">
      <div class="formGroup">
        <div class="value">
          <select class="setInput select fz14" v-model="tkNum" @change="changeEagle">
            <option value="">{{valueOfOption}}</option>
            <option v-for="(num, i) in ticketList" :key="i" :value="num.number">{{ num.tkNum }}</option>
          </select>
        </div>
      </div>

    </div>
    </div>
    <div class="historyList mb30">
      <div class="detailBox">
        <div class="box" v-for="(item, i) in list" :key="i">
          <div class="mainBox leftText">
            <div class="titleBox">
              <div class="mr5 logo">
                <img class="attendance" :src="item.logo"></div>
              <div class="content">
                <p><strong>vs {{item.match}}</strong></p>
                <p class="fw400">{{item.set_game_date}}</p>
              </div>
              <div class="alert attendance">
                <span class="alertBox" :class="item.setEnter ? 'red' : 'mdGray'">{{item.setEnter ? `${item.set_entrance_time} 입장` : '미입장'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <eagle-box
      v-show="isEagle"
      :title="btnTmp.title"
      :sub-title="btnTmp.subTitle"
      :btn-name="btnTmp.btnName"
      :btnType="btnTmp.btnType"
      :is-btn="btnTmp.isBtn"
    />
    <div class="mt30">
      <button v-if="ticketList.length === 0" type="button" class="btn full black" @click="addMembership">시즌권 등록</button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from '@/api/index'
import EagleBox from '@/common/EagleBox.vue'
import VueBarcode from 'vue-barcode'
import 'dayjs/locale/ko'
import imgPath from '@/library/imgPath'
dayjs.locale('ko')

export default {
  name: 'History',
  components: {
    EagleBox,
    // eslint-disable-next-line vue/no-unused-components
    barcode: VueBarcode
  },
  created () {
    this.year = dayjs().format('YYYY')
    this.fetchList()
  },
  computed: {
    name () {
      return this.$store.state.user.name
    }
  },
  data () {
    return {
      valueOfOption: '시즌권을 선택하세요',
      year: '',
      tkNum: '',
      userName: '',
      ticketList: [],
      btnTmp: {
        title: '시즌권을 먼저 선택해주세요!',
        subTitle: '시즌권을 선택하시면 입장 결과를 확인 할 수 있습니다.',
        isBtn: false,
        btnName: '',
        btnType: ''
      },
      list: [],
      isEagle: true
    }
  },
  methods: {
    fetchList () {
      api.get('/ticket/season') // 연간회원권 조회
        .then(res => {
          const ticketList = res.data.list
          if (ticketList) {
            // eslint-disable-next-line array-callback-return
            ticketList.map(row => {
              row.tkNum = `${row.product} ${row.ticket_seat_1} ${row.ticket_seat_2} ${row.ticket_seat_3} (${row.number
              })`
            })
            this.ticketList = ticketList
            if (this.ticketList.length === 0) {
              this.btnTmp.title = '시즌권을 등록 해주세요!'
              this.btnTmp.subTitle = '시즌권을 구매하시면 모바일 시즌권을<br>확인 할 수 있습니다.'
            }
          }
        })
    },
    addMembership () {
      this.$router.push({name: 'addMembership'})
    },
    listStatus () {
      const num = this.tkNum
      if (num === '') {
        this.list = []
        return
      }
      api.get(`/ticket/season/${num}`)
        .then(res => {
          this.list = res.data.list
          this.list?.map(row => {
            row.match = row.away_team_name
            row.matchDate = dayjs(row.game_date).format('YYYY.MM.DD')
            row.set_entrance_time = row.entrance_time.substring(0, 5)
            row.logo = imgPath.setTeamLogo(row.away_team)
            row.set_game_date = `${dayjs(row.game_date).format('YYYY.MM.DD')} (${dayjs(row.game_date).format('ddd')}) ${row.game_time.substring(0, 5)}`
            row.entrance_state_code === 'ENTERED' ? row.setEnter = true : row.setEnter = false
            return row
          })
          if (this.list.length === 0) {
            this.isEagle = true
            this.btnTmp.title = `${this.name}님, 해당 시즌권 입장기록이 없습니다!`
            this.btnTmp.subTitle = '경남FC와 함께해주세요!'
          } else {
            this.isEagle = false
          }
        })
        .catch(() => { this.isBtnClick = false })
    },
    changeEagle () {
      this.listStatus()
      document.activeElement.blur()
      this.isEagle = true
      this.btnTmp.title = '시즌권을 먼저 선택해주세요!'
      this.btnTmp.subTitle = '시즌권을 선택하시면 입장 결과를 확인 할 수 있습니다.'
    }
  }
}
</script>
<style>
.setInput {
  width: 100%;
  padding: 20px 20px 20px;
  border-radius: 10px;
  border: none;
}
.justify_content_center {
  display: flex;
  justify-content: center;
}
.seasonTicket {
  position: relative;
  width: -webkit-fill-available;
  //height: 0;
  padding-bottom: 38%;
  color: $white;
  border-radius: 20px;
  background: url('~@/assets/img/season_bg.png') 50% 50% / cover no-repeat;
  .contentBox {
    font-size: 23px;
    position: relative;
    top: 55px;
    right: -40px;
  }
  .barcodeBox {
    text-align: center;
    background-color: #FFFFFF;
    width: 80%;
    margin-left: 10%;
    padding-top: 25px;
    padding-bottom: 20px;
    border-radius: 20px;
    margin-right: 10%;
    position: relative;
    top: 115px;
  }
}

@media screen and (max-width: 767px) {
  .seasonTicket {
    padding-bottom: 30%;
    .contentBox {
      font-size: 14px;
      position: relative;
      top: 35px;
      right: -45px;
    }
    .barcodeBox {
      position: relative;
      top: 60px;
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 360px) {
  .seasonTicket {
    padding-bottom: 25%;
    .contentBox {
      font-size: 15px;
      position: relative;
      top: 31px;
      right: -40px;
    }
    .barcodeBox {
      position: relative;
      top: 50px;
      width: 90%;
      margin-left: 5%;
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}
</style>
